import React, { useRef, useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
/*  Internation phoneinput */
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import {
  Form,
  Input,
  Label,
  Col,
  Row,
  FormFeedback,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  createAgendaOrganiser,
  updateAgendaOrganiser,
} from "../../../../toolkitStore/FetnaSlice/AgendaOrganiserSlice"

function AgendaOrganiserForm({ toggleRightCanvas }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { OrganiserisSuccessfully, isUserEdit } = useSelector(
    state => state.OrganiserReducer
  )
  const [selectedData, SetSelectData] = useState()

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  // console.log("event_id", event_id_c)
  // const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
  // console.log("venue_id", venue_id_c)

  // useEffect(() => {
  //   if (OrganiserisSuccessfully) {
  //     toggleRightCanvas()
  //   }
  // }, [OrganiserisSuccessfully])

  useEffect(() => {
    if (isUserEdit) {
      const editData = JSON.parse(localStorage.getItem("editUser"))
      SetSelectData(editData)
    }
  }, [isUserEdit])

  //Cancel
  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("editUser")
    SetSelectData("")
    toggleRightCanvas()
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      //   master_event_id: selectedData?.master_event_id || event_id_c.EventId,
      //   master_venue_id: selectedData?.master_venue_id || venue_id_c.VenueId,
      name: selectedData?.name || "",
      contact_number: selectedData?.contact_number || "",
      email_id: selectedData?.email_id || "",
      role: selectedData?.role || "",
      active_status: selectedData?.active_status || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Enter Your name"),
      contact_number: Yup.string().required("Enter Your  Contact Number"),
      email_id: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      role: Yup.string().required("Enter Your role"),
    }),
    onSubmit: (values, actions) => {
      {
        if (selectedData?._id) {
          values["master_event_id"] = selectedData?.master_event_id
          values["master_date_id"] = selectedData?.master_date_id
          values["master_hall_id"] = selectedData?.master_hall_id
          values["master_location_id"] = selectedData?.master_location_id
          values["master_agenda_id"] = selectedData?.master_agenda_id
          values["_id"] = selectedData?._id

          dispatch(updateAgendaOrganiser(values))
          toggleRightCanvas()
        } else {
          const hallId = JSON.parse(localStorage.getItem('hall_id')).hallId;
          const location_id = JSON.parse(localStorage.getItem('location_id')).LocationId;
          const agenda_id = JSON.parse(localStorage.getItem('agenda_id')).AgendaId;

          values["master_event_id"] = event_id_c.EventId
          values["master_date_id"] = event_id_c.DateId
          values["master_hall_id"] = hallId;
          values["master_location_id"] = location_id;
          values["master_agenda_id"] = agenda_id;
          dispatch(createAgendaOrganiser(values))
          toggleRightCanvas()
        }
      }
      actions.resetForm()
    },
  })
  return (
    <>
      <Container fluid>
        <Form
          onSubmit={event => {
            event.preventDefault()
            validation.handleSubmit()
          }}
        >
          <Row className="col-12 ">
            <Col className="col-12 d-flex flex-wrap">
              <div className="mb-3 col-12">
                <Label htmlFor="name"> Name</Label>
                <Input
                  name="name"
                  className="form-control"
                  placeholder="Enter  Event Contact Person"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3 col-12 ">
                <Label htmlFor="contact_number">Contact Number</Label>
                {/* <PhoneInput
                  type="tel"
                  id="contact_number"
                  name="contact_number"
                  className="form-group"
                  value={validation.values.contact_number}
                  onChange={value =>
                    validation.setFieldValue("contact_number", value)
                  }
                  onBlur={validation.handleBlur}
                  countryCallingCodeEditable={true}
                  inv={
                    validation.touched.contact_number &&
                    validation.errors.contact_number
                      ? true
                      : false
                  }
                />
                {validation.touched.contact_number &&
                  validation.errors.contact_number && (
                    <div className="error" style={{ fontSize: "12px" }}>
                      {validation.errors.contact_number}
                    </div>
                  )} */}
                <Input
                  name="contact_number"
                  className="form-control"
                  placeholder="Enter Event Conatct Phone Number"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.contact_number || ""}
                  invalid={
                    validation.touched.contact_number &&
                      validation.errors.contact_number
                      ? true
                      : false
                  }
                />
                {validation.touched.contact_number &&
                  validation.errors.contact_number ? (
                  <FormFeedback type="invalid">
                    {validation.errors.contact_number}
                  </FormFeedback>
                ) : null}
              </div>
              <Col className="col-12 d-flex flex-wrap justify-content-between">
                <div className="mb-3 col-12">
                  <Label htmlFor="email_id"> Email Id</Label>
                  <Input
                    name="email_id"
                    className="form-control"
                    placeholder="Enter Event Conatct Email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email_id || ""}
                    invalid={
                      validation.touched.email_id && validation.errors.email_id
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email_id && validation.errors.email_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email_id}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3 col-12 ">
                  <Label htmlFor="role"> Role</Label>
                  <Input
                    name="role"
                    className="form-control"
                    placeholder="Enter Event Conatct Phone Number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.role || ""}
                    invalid={
                      validation.touched.role && validation.errors.role
                        ? true
                        : false
                    }
                  />
                  {validation.touched.role && validation.errors.role ? (
                    <FormFeedback type="invalid">
                      {validation.errors.role}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {/* <div className="mb-3 col-12">
                        <Label htmlFor="venue_status"> Active Status </Label>
                        <select
                          id="venue_status"
                          name="venue_status"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.venue_status}
                        >
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </select>
                      </div> */}
            </Col>
            <Col className="d-flex justify-content-center">
              <div className=" text-center mt-3 ">
                <button type="submit" className="btn btn-success w-md px-5">
                  {"Submit"}
                </button>
              </div>
              <div className=" text-center mt-3 mx-3 ">
                <button
                  type="button"
                  className="btn btn-danger w-md px-5"
                  onClick={Cancel}
                >
                  {"Cancel"}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}

export default AgendaOrganiserForm
