import React, { useMemo, useState, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
// import Organiser from "./Organiser"
import AgendaOrganiserForm from "./AgendaOrganiserForm"
import {
  Alert,
  CardBody,
  Card,
  Row,
  Col,
  Modal,
  UncontrolledTooltip,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader, Container
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

import Breadcrumbs from "../../../../components/Common/Breadcrumb";


import moment from "moment"

//import components
import TableContainer from "components/Common/TableContainer"

// slice
import {
  fetchAgendaOrganiser,
  editUser,
  deleteAgendaOrganiser,
} from "../../../../toolkitStore/FetnaSlice/AgendaOrganiserSlice"

function AgendaOrganiserList(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  const hall_agenda_c = JSON.parse(localStorage.getItem("agenda_id"))
  // console.log("event_id", event_id_c)
  // const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))
  // console.log("venue_id", venue_id_c)

  const { AgendaOrganiserList, AgendaOrganiserisSuccessfully, isUserEdit } = useSelector(
    state => state.AgendaOrganiserReducer
  )

  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  useEffect(() => {
    dispatch(
      fetchAgendaOrganiser({
        master_agenda_id : hall_agenda_c.AgendaId
      })
    )
  }, [isRight, modal_backdrop])

  const columns = useMemo(
    () => [
      {
        Header: " Name",
        accessor: "name",
        disableFilters: true,
      },
      {
        Header: "Conatct Number",
        accessor: "contact_number",
        disableFilters: true,
        Cell: cellProps => {
          let sd = cellProps.row.original.contact_number
          return (
            <ul className="list-unstyled">
              <p>{sd}</p>
            </ul>
          )
        },
      },
      {
        Header: "Email",
        accessor: "email_id",
        disableFilters: true,
      },
      {
        Header: "Role",
        accessor: "role",
        disableFilters: true,
      },

      {
        Header: "Action",
        accessor: "event_status",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    dispatch(editUser(SData))
                    toggleRightCanvas()

                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    console.log("delete", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })

                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Organizers" />
          <Row className="">
            <Card>
              <CardBody className="border-botton">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-1 flex-grow-1 text-primary">
                    {/* {event_id_c.EventName + " / " + venue_id_c.VenueName} */}
                  </h5>
                  <div className="flex-shrink-0">
                    <Link
                      to="#!"
                      className="btn btn-primary me-1"
                      onClick={e => {
                        localStorage.removeItem("editUser")
                        toggleRightCanvas()
                      }}
                    >
                      Add Organiser
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-secondary me-1"
                      onClick={e => {
                        e.preventDefault()
                        history.back()
                      }}
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(
                          fetchAgendaOrganiser()
                        )
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={AgendaOrganiserList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
                {AgendaOrganiserList.length > 10 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}

              </CardBody>
            </Card>

          </Row>
          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              style={{ borderBottom: "1px solid #dedede" }}
            >
              Add / Edit Organiser Details
            </OffcanvasHeader>
            <OffcanvasBody>
              <AgendaOrganiserForm toggleRightCanvas={toggleRightCanvas} />
            </OffcanvasBody>
          </Offcanvas>

          {/*delete Modal */}
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Organiser ?</p>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-danger mx-3"
                onClick={() => {
                  setmodal_backdrop(false)
                  dispatch(deleteAgendaOrganiser(delete_data))
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AgendaOrganiserList
