import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { del, get, post, put } from "../../helpers/api_helper"

import { APIServerIP } from "../../helpers/Sats_api_helper"

const initialAttractionState = {
  // companyInfo: null,
  newAttractionList: [],
  isSuccessfullyAdded: false,
  isUserEdit: false,
}

const AttractionSlice = createSlice({
  name: "AttractionValue",
  initialState: initialAttractionState,
  reducers: {
    setAttractionList: (state, action) => {
      state.newAttractionList = action.payload
      // state.newAttractionList = [...state.newAttractionList, action.payload]
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    setAttractionSuccessfullyAdded: (state, action) => {
      state.isSuccessfullyAdded = true
    },
    resetAttraction: (state, action) => {
      state.isSuccessfullyAdded = false
      state.isUserEdit = false
    },
  },
})

export const createAttraction = (image, values) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))

    dispatch(resetAttraction())
    await fetch(`${APIServerIP}sa-attractions/create-attractions`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(setAttractionSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })
  }
}

export const updateAttraction = (image, values) => {

  return async dispatch => {

    const formData1 = new FormData()
    formData1.append("file", image),
      formData1.append("values", JSON.stringify(values))

    dispatch(resetAttraction())
    await fetch(`${APIServerIP}sa-attractions/update-attractions`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData1,
    })
      .then(res => {
        if (res.status == 200) {
          dispatch(setAttractionSuccessfullyAdded())
          localStorage.removeItem("editUser")
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}

export const deleteAttraction = id => {
  return async dispatch => {
    try {
      dispatch(resetAttraction())
      const responseData = await post(
        `${APIServerIP}sa-attractions/delete-attractions`,
        id
      )

      if (responseData.response_code === 200) {
        dispatch(setAttractionSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchAttraction = () => {
  return async dispatch => {
    try {
      let responseData = await get(`${APIServerIP}sa-attractions/fetch-attractions`)
      if (responseData.response_code === 200) {
        dispatch(setAttractionList(responseData.data))
        dispatch(resetAttraction())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setAttractionList,
  setAttractionSuccessfullyAdded,
  resetAttraction,
  setUserEdit,
} = AttractionSlice.actions

export default AttractionSlice.reducer
