import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { APIServerIP } from "../../../../helpers/Sats_api_helper"
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
    Container,
} from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
    createLocation,
    updateLocation,
    clearLocationSuccessfullyAdded
} from "../../../../toolkitStore/FetnaSlice/LocationSlice";
import { fetchDay } from "../../../../toolkitStore/FetnaSlice/DaySlice";
import { fetchFetnaEvent } from "../../../../toolkitStore/FetnaSlice/fetnaSlice";
import { Country } from "../../../../components/country-state-city/countries";
import { State } from "../../../../components/country-state-city/states";

const FetnaLocationForm = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { LocationisSuccessfully, isUserEdit } = useSelector(
        (state) => state.LocationReducer
    );

    const handleCkeditorIn = (event, editor) => {
        const data = editor.getData();
        validation.setFieldValue("description", data);
    };

    const days = useSelector((state) => state.DayReducer.DayList);
    const events = useSelector((state) => state.FetnaReducer.newFetnaEventList);

    const [selectedData, setSelectedData] = useState(null);

    const event_id_c = JSON.parse(localStorage.getItem("event_id"));
    const location_id_c = JSON.parse(localStorage.getItem("location_id"));
    const [image, SetImage] = useState()

    useEffect(() => {
        dispatch(fetchFetnaEvent())
        return () => {}
    }, [dispatch]);

    useEffect(() => {
        if (events) {
            const editData = JSON.parse(localStorage.getItem("editUser"));
            if (editData) {

                console.log("editData", editData)
                setSelectedData(editData);
                const selectedEvent = events.find(event => event.name === editData.event_name);
                if (selectedEvent?._id) {
                    dispatch(fetchDay(selectedEvent._id));
                }
            }
        }
        return () => {}
    }, [events])

    useEffect(() => {
        if (LocationisSuccessfully) {
        //   console.log("Navigating to /fetna-location-list");
          navigate("/fetna-location-list");
          dispatch(clearLocationSuccessfullyAdded());
        }
      }, [LocationisSuccessfully, navigate, dispatch]);





    const Cancel = (e) => {
        e.preventDefault();
        localStorage.removeItem("editUser");
        setSelectedData("");
        navigate("/fetna-location-list");
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            event_name: selectedData?.event_name || "",
            day: selectedData?.day || "",
            name: selectedData?.name || "",
            address: selectedData?.address || "",
            city: selectedData?.city || "",
            state: selectedData?.state || "",
            country: selectedData?.country || "",
            zipcode: selectedData?.zipcode || "",
            country_code: selectedData?.country_code || "",
            latitude: selectedData?.latitude || "",
            longitude: selectedData?.longitude || "",
            description: selectedData?.description || "",
            master_event_id: selectedData?.master_event_id || "",
            image_name: selectedData?.image_name || "",
            master_date_id: selectedData?.master_date_id || "" // Ensure this is populated correctly
        },

        validationSchema: Yup.object({
            event_name: Yup.string().required("Enter the Event Name"),
            day: Yup.string().required("Day is Required"),
            name: Yup.string().required("Enter the Location location"),
            address: Yup.string().required("Enter the location Address"),
            city: Yup.string().required("Enter the location City"),
            state: Yup.string().required("Enter the location State"),
            country: Yup.string().required("Enter the location Country"),
            zipcode: Yup.string().required("Enter the location Zipcode"),
            country_code: Yup.string().required("Enter the location Country Code")
        }),

        onSubmit: (values, actions) => {
            if (selectedData?._id) {
                values["master_event_id"] = selectedData.master_event_id;
                values["_id"] = selectedData?._id
                // values["master_date_id"] is already set by handleChange for day select
                dispatch(updateLocation(image, values));
            } else {
                dispatch(createLocation(image, values));
            }

            //navigate("/fetna-location-list");
            actions.resetForm();
        },
    });

    const filterstates = State.filter(
        sta => sta.country_name === validation.values.country
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItem="Location Informations" />
                    <Row>
                        <Form
                            onSubmit={(event) => {
                                event.preventDefault();
                                validation.handleSubmit();
                            }}
                        >
                            <Row className="col-12 ">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex align-items-center justify-content-end border-bottom border-secondary pb-3">
                                            <div className="mb-1 flex-shrink-0">
                                                <Link
                                                    to="/fetna-location-list"
                                                    className="btn btn-secondary me-1"
                                                >
                                                    Back
                                                </Link>
                                                <Link
                                                    to="#!"
                                                    onClick={() => {
                                                        /*  dispatch(fetchServices(userInformation.m_company_id)) */
                                                    }}
                                                    className="btn btn-light me-1"
                                                >
                                                    <i className="mdi mdi-refresh"></i>
                                                </Link>
                                            </div>
                                        </div>

                                        <Row className="mb-3 mt-3">
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="day">Select Event</Label>
                                                    <select
                                                        id="event_name"
                                                        name="event_name"
                                                        className={`form-select ${validation.touched.event_name && validation.errors.event_name ? 'is-invalid' : ''}`}
                                                        onChange={(e) => {
                                                            const selectedEvent = events.find(event => event.name === e.target.value);
                                                            validation.setFieldValue("event_name", e.target.value);
                                                            validation.setFieldValue("master_event_id", selectedEvent?._id);
                                                            if (selectedEvent?._id) {
                                                                dispatch(fetchDay(selectedEvent._id));
                                                            }
                                                        }}
                                                        value={validation.values.event_name}
                                                    >
                                                        <option value="">Select</option>
                                                        {events.length > 0 ? (
                                                            events.map((event) => (
                                                                <option key={event._id} value={event.name}>
                                                                    {event.name}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option>No events available</option>
                                                        )}
                                                    </select>
                                                    {validation.touched.event_name && validation.errors.event_name && (
                                                        <div className="invalid-feedback">
                                                            {validation.errors.event_name}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3 mt-3">
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="day">Select Event Date</Label>
                                                    <select
                                                        id="day"
                                                        name="day"
                                                        className={`form-select ${validation.touched.day && validation.errors.day ? 'is-invalid' : ''}`}
                                                        onChange={(e) => {
                                                            const selectedDay = days.find(day => day.date === e.target.value);
                                                            validation.setFieldValue("day", e.target.value);
                                                            validation.setFieldValue("master_date_id", selectedDay?._id);
                                                        }}
                                                        value={validation.values.day}
                                                    >
                                                        <option value="">Select</option>
                                                        {days.length > 0 ? (
                                                            days.map((day) => (
                                                                <option key={day._id} value={day.date}>
                                                                    {`Day ${day.day} - ${new Date(day.date).toLocaleDateString()}`}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option>Event date not available</option>
                                                        )}
                                                    </select>
                                                    {validation.touched.day && validation.errors.day && (
                                                        <div className="invalid-feedback">
                                                            {validation.errors.day}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <Row className="mb-3 mt-3">
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="day"> Event Name</Label>
                                                    <select
                                                        id="event_name"
                                                        name="event_name"
                                                        className={`form-select ${validation.touched.event_name && validation.errors.event_name ? 'is-invalid' : ''}`}
                                                        onChange={(e) => {
                                                            validation.setFieldValue("event_name", e.target.value)
                                                            const events = name?.find(d => d.name == e.target.value)
                                                            validation.setFieldValue("master_date_id", date?._id)
                                                            validation.setFieldValue("master_event_id", date?.master_event_id)
                                                        }}
                                                        value={validation.values.event_name}
                                                    >
                                                        <option value="">Select a Event</option>
                                                        {events.length > 0 ? (
                                                            events.map((event) => (
                                                                <option key={event.name} value={event.name}>
                                                                    {`${event.name}`}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option>No days available</option>
                                                        )}
                                                    </select>
                                                    {validation.touched.event_name && validation.event_name.day && (
                                                        <div className="invalid-feedback">
                                                            {validation.errors.event_name}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3 mt-3">
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="day"> Day</Label>
                                                    <select
                                                        id="day"
                                                        name="day"
                                                        className={`form-select ${validation.touched.day && validation.errors.day ? 'is-invalid' : ''}`}
                                                        onChange={(e) => {
                                                            validation.setFieldValue("day", e.target.value)
                                                            const date = days?.find(d => d.day == e.target.value)
                                                            console.log("date", date);
                                                            validation.setFieldValue("master_date_id", date?._id)
                                                            validation.setFieldValue("master_event_id", date?.master_event_id)
                                                        }}
                                                        value={validation.values.day}
                                                    >
                                                        <option value="">Select a Day</option>
                                                        {days.length > 0 ? (
                                                            days.map((day) => (
                                                                <option key={day.day} value={day.day}>
                                                                    {`Day ${day.day} - ${new Date(day.date).toLocaleDateString()}`}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option>No days available</option>
                                                        )}
                                                    </select>
                                                    {validation.touched.day && validation.errors.day && (
                                                        <div className="invalid-feedback">
                                                            {validation.errors.day}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row> */}


                                        <Row className="mb-3">
                                            <Col className="">
                                                <Label htmlFor="location">Location Name</Label>
                                                <Input
                                                    name="name"
                                                    className={`form-control`}
                                                    placeholder="Enter Location Name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name && validation.errors.name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.name && validation.errors.name ? (
                                                    <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                                                        {validation.errors.name}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={12} className="mb-3">
                                                <div>
                                                    <Label htmlFor="description">Description</Label>
                                                    <CKEditor
                                                        config={{
                                                            placeholder: "Write about event...",
                                                        }}
                                                        editor={ClassicEditor}
                                                        data={validation.values.description}
                                                        onChange={handleCkeditorIn}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="address">Address</Label>
                                                    <Input
                                                        name="address"
                                                        className={`form-control`}
                                                        placeholder="Enter Location Address"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address || ""}
                                                        invalid={
                                                            validation.touched.address &&
                                                                validation.errors.address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.address &&
                                                        validation.errors.address ? (
                                                        <FormFeedback
                                                            type="invalid"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.address}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="country_code">Country Code</Label>
                                                    <select
                                                        id="country_code"
                                                        name="country_code"
                                                        className="form-select"
                                                        onChange={e =>
                                                            validation.setFieldValue(
                                                                "country_code",
                                                                e.target.value
                                                            )
                                                        }
                                                        value={validation.values.country_code}
                                                    >
                                                        <option>Select country code</option>
                                                        {Country.map(country => (
                                                            <option
                                                                value={country.phone_code}
                                                                key={country.id}
                                                            >
                                                                {country.phone_code}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="country">Country</Label>
                                                    <select
                                                        id="country"
                                                        name="country"
                                                        className={`form-select ${validation.touched.country && validation.errors.country ? 'is-invalid' : ''}`}
                                                        onChange={e =>
                                                            validation.setFieldValue(
                                                                "country",
                                                                e.target.value
                                                            )
                                                        }
                                                        value={validation.values.country}
                                                    >
                                                        <option>Select country</option>
                                                        {Country.map(country => (
                                                            <option value={country.name} key={country.id}>
                                                                {country.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {validation.touched.country && validation.errors.country ? (
                                                        <FormFeedback
                                                            type="invalid"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.country}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="state">State</Label>
                                                    <select
                                                        id="state"
                                                        name="state"
                                                        className={`form-select ${validation.touched.state && validation.errors.state ? 'is-invalid' : ''}`}
                                                        onChange={e =>
                                                            validation.setFieldValue("state", e.target.value)
                                                        }
                                                        value={validation.values.state}
                                                    >
                                                        <option value="">Select state</option>
                                                        {filterstates.map(state => (
                                                            <option
                                                                value={state.name}
                                                                key={state.id}
                                                                className="d-flex flex-grow-1"
                                                            >
                                                                {state.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {validation.touched.state && validation.errors.state ? (
                                                        <FormFeedback
                                                            type="invalid"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.state}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="city">City</Label>
                                                    <Input
                                                        name="city"
                                                        className={`form-control`}
                                                        placeholder="Enter city name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback
                                                            type="invalid"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="zipcode">ZipCode</Label>
                                                    <Input
                                                        name="zipcode"
                                                        className={`form-control`}
                                                        placeholder="Enter zipcode"
                                                        type="number"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.zipcode || ""}
                                                        invalid={
                                                            validation.touched.zipcode &&
                                                                validation.errors.zipcode
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.zipcode &&
                                                        validation.errors.zipcode ? (
                                                        <FormFeedback
                                                            type="invalid"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.zipcode}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col className="col-3">
                                                <div className="">
                                                    <Label htmlFor="latitude">Latitude</Label>
                                                    <Input
                                                        name="latitude"
                                                        className={`form-control`}
                                                        placeholder="Enter Latitude"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.latitude || ""}
                                                        invalid={
                                                            validation.touched.latitude &&
                                                                validation.errors.latitude
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.latitude &&
                                                        validation.errors.latitude ? (
                                                        <FormFeedback
                                                            type="invalid"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.latitude}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col className="col-3">
                                                <div className="">
                                                    <Label htmlFor="longitude">Longitude</Label>
                                                    <Input
                                                        name="longitude"
                                                        className={`form-control`}
                                                        placeholder="Enter Latitude"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.longitude || ""}
                                                        invalid={
                                                            validation.touched.longitude &&
                                                                validation.errors.longitude
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.longitude &&
                                                        validation.errors.longitude ? (
                                                        <FormFeedback
                                                            type="invalid"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.longitude}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={6} className="">
                                                <div className="">
                                                    <Label htmlFor="image_name">Location Photos</Label>
                                                    <Input
                                                        name="image_name"
                                                        className="form-control"
                                                        type="file"
                                                        onChange={e => {
                                                            validation.setFieldValue(
                                                                "image_name",
                                                                e.currentTarget.files[0].name
                                                            )
                                                            SetImage(e.currentTarget.files[0])
                                                        }}
                                                        invalid={
                                                            validation.touched.image_name &&
                                                                validation.errors.image_name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.image_name &&
                                                        validation.errors.image_name ? (
                                                        <div
                                                            type="invalid"
                                                            className="invalid-feedback"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {validation.errors.image_name}
                                                        </div>
                                                    ) : selectedData?.image_name ? (
                                                        <a
                                                            href={`${APIServerIP}assets/fetna_location/${selectedData?.image_name}`}
                                                        >
                                                            {selectedData?.image_name}
                                                        </a>
                                                    ) : (
                                                        validation.values.image_name
                                                    )}
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col className="d-flex mt-3 justify-content-end">
                                                <div className=" text-center mt-3 ">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success w-md px-5"
                                                    >
                                                        {selectedData?._id ? "Update" : "Submit"}
                                                    </button>
                                                </div>
                                                <div className=" text-center mt-3 mx-3 ">
                                                    <button
                                                        onClick={()=>{Cancel()}}
                                                        className="btn btn-danger w-md px-5"
                                                    >
                                                        {"Cancel"}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FetnaLocationForm