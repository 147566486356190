import React, { useRef, useState, useEffect } from "react"
/* import axios from "axios" */
import { Link, useNavigate } from "react-router-dom"
import { APIServerIP } from "../../../../helpers/Sats_api_helper"
import moment from "moment"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

//Import Date Picker
import { format } from "date-fns"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  createAttraction,
  updateAttraction,
  resetAttraction,
} from "../../../../toolkitStore/FetnaSlice/AttractionSlice"
import { Feedback } from "@mui/icons-material"

function AttractionForm(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { newAttractionList, isSuccessfullyAdded, isUserEdit } = useSelector(
    state => state.AttractionReducer
  )

  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    validation.setFieldValue("description", data)
  }

  document.title = "Form File Upload | Skote - React Admin & Dashboard Template"

  const [image, SetImage] = useState()
  const [selectedData, SetSelectData] = useState()
  const [dis4, SetDis4] = useState(false)
  const [dis1, SetDis1] = useState(false)

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))

  useEffect(() => {
    const saattractiveInfo = JSON.parse(localStorage.getItem("@saattractiveInfo"))
    console.log("saattractiveInfo", saattractiveInfo)
    if (saattractiveInfo) {
      SetSelectData(saattractiveInfo.saAttractivesInfo)
    }
    return () => {}
  }, [])
  //Cancel
  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("editUser")
    SetSelectData("")
    navigate("/sa-attractions-list")
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: selectedData?.name || "",
      description: selectedData?.description || "",
      image_name: selectedData?.image_name || "",
      url: selectedData?.url || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(" Enter Your Attraction Name"),
      // description: Yup.string().required("SA Attraction description"),
      // image_name: Yup.string().required("A photo is required"),
      url: Yup.string().required("URL is required"),
    }),
    onSubmit: (values, actions) => {
      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        console.log("mm", values)
        dispatch(updateAttraction(image, values))
      } else {
        dispatch(createAttraction(image, values))
      }

      actions.resetForm()
      SetImage("")
      navigate("/sa-attractions-list")
      console.log(values)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Information" />
          <Row>
            <Form
              onSubmit={event => {
                event.preventDefault()
                validation.handleSubmit()
              }}
            >
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center border-bottom border-secondary pb-3">
                    <h5 className="mb-1 card-title flex-grow-1 text-primary ">
                      {event_id_c ? event_id_c.EventName : "New Attraction"}
                    </h5>
                    <div className="mb-1 flex-shrink-0">
                      <Link to="/sa-attractions-list" className="btn btn-secondary me-1">
                        Back
                      </Link>
                      <Link
                        to="#!"
                        onClick={() => {
                        }}
                        className="btn btn-light me-1"
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                  </div>

                  <Row className="mt-3 mb-3">
                    <Col lg={12} className="mb-3">
                      <div className="">
                        <Label htmlFor="name">Place Name</Label>
                        <Input
                          name="name"
                          className={`form-control`}
                          placeholder="Enter Name of Place"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                          disabled={dis4}
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={12} className="mb-3">
                      <div
                        className="" //position-relative
                      >
                        <Label htmlFor="how_to">Description</Label>
                        <CKEditor
                          config={{
                            placeholder: "Write about the place...",
                          }}
                          editor={ClassicEditor}
                          data={validation.values.description}
                          onChange={handleCkeditorIn}
                          disabled={dis4}
                        />
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="">
                        <Label htmlFor="image_name">Image</Label>
                        <Input
                          name="image_name"
                          className="form-control"
                          placeholder="Upload Image"
                          type="file"
                          onChange={e => {
                            validation.setFieldValue(
                              "image_name",
                              e.currentTarget.files[0].name
                            )
                            SetImage(e.currentTarget.files[0])
                          }}
                          invalid={
                            validation.touched.image_name &&
                            validation.errors.image_name
                              ? true
                              : false
                          }
                          disabled={dis1}
                        />
                        {validation.touched.image_name &&
                        validation.errors.image_name ? (
                          <div
                            type="invalid"
                            className="invalid-feedback"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.image_name}
                          </div>
                        ) : selectedData?.image_name ? (
                          <a
                            href={`${APIServerIP}uploads/${selectedData?.image_name}`}
                          >
                            {selectedData?.image_name}
                          </a>
                        ) : (
                          validation.values.image_name
                        )}
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="">
                        <Label htmlFor="url">Website URL</Label>
                        <Input
                          name="url"
                          className={`form-control`}
                          placeholder="Enter website Url"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.url || ""}
                          invalid={
                            validation.touched.url && validation.errors.url
                              ? true
                              : false
                          }
                          disabled={dis4}
                        />
                        {validation.touched.url && validation.errors.url ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.url}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Col className="d-flex mt-3 justify-content-end">
                    <div className=" text-center mt-3 ">
                      <button
                        type="submit"
                        className="btn btn-success w-md px-5"
                      >
                        {selectedData?._id ? "Update" : "Submit"}
                      </button>
                    </div>
                    <div className=" text-center mt-3 mx-3 ">
                      <button
                        onClick={Cancel}
                        className="btn btn-danger w-md px-5"
                      >
                        {"Cancel"}
                      </button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AttractionForm
