import React, { useMemo, useState, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import FetnaHallForm from "./FetnaHallForm"
import {
  Alert,
  CardBody,
  Card,
  Row,
  Col,
  Modal,
  UncontrolledTooltip,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader, Container
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

import Breadcrumbs from "../../../../components/Common/Breadcrumb";


import moment from "moment"

//import components
import TableContainer from "components/Common/TableContainer"

// slice
import {
  fetchHall,
  editUser,
  deleteHall,
} from "../../../../toolkitStore/FetnaSlice/HallSlice"

function FetnaHallList(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  console.log("event_id", event_id_c)

  const location_id_c = JSON.parse(localStorage.getItem("location_id"))
  // console.log("venue_id", venue_id_c)

  const { HallList, HallisSuccessfully, isUserEdit } = useSelector(
    state => state.HallReducer
  )

  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  useEffect(() => {

    dispatch(
      fetchHall({
        // master_event_id: event_id_c.EventId,
        master_location_id: location_id_c.LocationId,
      })
    )

  }, [isRight, modal_backdrop])

  const columns = useMemo(
    () => [
      {
        Header: " Name",
        accessor: "name",
        disableFilters: true,
      },
      {
        Header: "Short Name",
        accessor: "short_name",
        disableFilters: true,
      },
      // {
      //   Header: "Latitude",
      //   accessor: "latitude",
      //   disableFilters: true,
      // },
      // {
      //   Header: "Longitude",
      //   accessor: "longitude",
      //   disableFilters: true,
      // },
      {
        Header: "Capacity",
        accessor: "capacity",
        disableFilters: true,
      },
      {
        Header: "Manage Agendas",
        disableFilters: true,
        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <ul className="list-unstyled hstack  gap-3 mb-0">
              <li>
                <Link
                  to="/fetna-agenda-list"
                  className="btn btn-sm btn-primary"
                  style={new Date(SData.end_datetime) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    const PassingData = {
                      hallName: SData.hall,
                      hallId: SData._id,
                      hallInfo: SData
                    }
                    localStorage.setItem(
                      "hall_id",
                      JSON.stringify(PassingData)
                    )
                  }}
                >
                  Manage Agendas
                </Link>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "Action",
        accessor: "event_status",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack d-flex gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    dispatch(editUser(SData))
                    toggleRightCanvas()

                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  {/* <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip> */}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    console.log("delete", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Manage Rooms" />
          <Row className="">
            <Card>
              <CardBody className="border-botton">
                <div className="d-flex align-items-center justify-content-between border-bottom border-secondary pb-3">
                  <div>
                    <div style={{ margin: 0, pading: 0 }}><label className="text-primary m-0" style={{ fontSize: 16, lineHeight: 1 }}>Location: {event_id_c.eventInfo.name}</label></div>
                    <div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Date: {moment(event_id_c.eventInfo.day).format("ll")}</label></div>
                    <div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Event: {event_id_c.eventInfo.event_name}</label></div>
                  </div>

                  <div className="flex-shrink-0">
                    <Link
                      to="#!"
                      className="btn btn-primary me-1"
                      onClick={e => {
                        localStorage.removeItem("editUser")
                        toggleRightCanvas()
                      }}
                    >
                      Add Room
                    </Link>
                    <Link
                      to="#!"
                      className="btn btn-secondary me-1"
                      onClick={e => {
                        e.preventDefault()
                        navigate("/fetna-location-list")
                      }}
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(
                          fetchHall({
                            master_event_id: event_id_c.EventId,
                            master_location_id: location_id_c.LocationId,
                          })
                        )
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>

                <Row className="mt-3">
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={HallList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
                {HallList.length > 10 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader
              toggle={toggleRightCanvas}
              style={{ borderBottom: "1px solid #dedede" }}
            >
              Add / Edit Room Details
            </OffcanvasHeader>
            <OffcanvasBody>
              <FetnaHallForm toggleRightCanvas={toggleRightCanvas} />
            </OffcanvasBody>
          </Offcanvas>

          {/*delete Modal */}
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Do you want Delete this Hall ?</p>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-danger mx-3"
                onClick={() => {
                  setmodal_backdrop(false)
                  dispatch(deleteHall(delete_data))
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FetnaHallList