import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { useSelector, useDispatch } from "react-redux"
import {
  Alert,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  CardTitle,
  Badge,
  Modal,
  UncontrolledTooltip,
  Button,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//import components
import TableContainer from "components/Common/TableContainer"
// slice

import { EditSeats, UpdateSeats } from "toolkitStore/SeatArrangement/SeatSlice"
import {
  fetchAgenda,
  editUser,
  deleteAgenda,
  activeInactiveAgenda,
} from "../../../../toolkitStore/FetnaSlice/AgendaSlice"

import{
  setHostList
} from "../../../../toolkitStore/FetnaSlice/fetnaSlice"

function FetnaAgendaList(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const [selectedData, SetSelectData] = useState()
  const hall_id_c = JSON.parse(localStorage.getItem("hall_id"))

  // const location_id = JSON.parse(localStorage.getItem('location_id')).LocationDay;
  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  const hallInfo = JSON.parse(localStorage.getItem("hall_id"))
  


  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  const { AgendaList, AgendaisSuccessfully } = useSelector(
    state => state.AgendaReducer
  )

  useEffect(() => {
    dispatch(setHostList(null))
    return()=>{}
  },[])

  useEffect(() => {
    if (AgendaList) {
      dispatch(fetchAgenda({
        master_hall_id : hall_id_c.hallId
      }))
    }
  }, [setmodal_backdrop, AgendaisSuccessfully])

  useEffect(() => {
    localStorage.removeItem("agenda_id")
    dispatch(fetchAgenda({
      master_hall_id : hall_id_c.hallId
    }))
  }, [])

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        Cell: cellProps => {
          var data = cellProps.row.original
          return <div>
            <div><span>{data.name}</span></div>
          </div>
        }
      },
      // {
      //   Header: "Start Time",
      //   accessor: "start_time",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     let sd = moment(cellProps.row.original.start_time).format(
      //       "llll"
      //     )

      //     return (
      //       <ul className="list-unstyled">
      //         <p>{sd}</p>
      //       </ul>
      //     )
      //   },
      // },
      // {
      //   Header: "End Time",
      //   accessor: "end_time",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     let sd = moment(cellProps.row.original.end_time).format(
      //       "llll"
      //     )

      //     return (
      //       <ul className="list-unstyled">
      //         <p>{sd}</p>
      //       </ul>
      //     )
      //   },
      // },
      // {
      //   Header: "Date",
      //   accessor: "dummy",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     var data = cellProps.row.original
      //     let sd = moment(cellProps.row.original.end_time).format(
      //       "llll"
      //     )
      //     return <div>
      //       <div><span>{sd}</span></div>
      //     </div>
      //   }
      // },
      {
        Header: "Start Time",
        accessor: "start_time",
        disableFilters: true,
        Cell: cellProps => {
          let sd = moment.utc(cellProps.row.original.start_time).format("h:mm:ss A");

          return (
            <ul className="list-unstyled">
              <p>{sd}</p>
            </ul>
          );
        },
      },
      {
        Header: "End Time",
        accessor: "end_time",
        disableFilters: true,
        Cell: cellProps => {
          let sd = moment.utc(cellProps.row.original.end_time).format("h:mm:ss A");

          return (
            <ul className="list-unstyled">
              <p>{sd}</p>
            </ul>
          );
        },
      },
      {
        Header: "Date",
        accessor: "dummy",
        disableFilters: true,
        Cell: cellProps => {
          var data = cellProps.row.original;
          let sd = moment.utc(cellProps.row.original.end_time).format("MMMM Do YYYY");
          return (
            <div>
              <div><span>{sd}</span></div>
            </div>
          );
        }
      },
      {
        Header: "Agenda Type",
        accessor: "agenda_type",
        disableFilters: true,
      },
      {
        Header: "Category",
        accessor: "group_category",
        disableFilters: true,
      },
      // {
      //   Header: "Room No",
      //   accessor: "room_no",
      //   disableFilters: true,
      // },
      // {
      //   Header: "Room Name",
      //   accessor: "room_name",
      //   disableFilters: true,
      // },
      {
        Header: "Host",
        disableFilters: true,
        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <ul className="list-unstyled hstack  gap-3 mb-0">
              <li>
                <Link
                  to="/agenda-host"
                  className="btn btn-sm btn-primary"
                  style={new Date(SData.end_datetime) < new Date() ? {pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    const PassingData = {
                      AgendaName: SData.agenda,
                      AgendaId: SData._id,
                      agendaInfo:SData
                    }
                    localStorage.setItem(
                      "agenda_id",
                      JSON.stringify(PassingData)
                    )
                  }}
                >
                  Manage Host
                </Link>
              </li>
            </ul>
          )
        },
      },
      {
        Header: "Organizers",
        disableFilters: true,
        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <ul className="list-unstyled hstack  gap-3 mb-0">
              <li>
                <Link
                  to="/agenda-organizers"
                  className="btn btn-sm btn-primary"
                  style={new Date(SData.end_datetime) < new Date() ? {pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    const PassingData = {
                      AgendaName: SData.agenda,
                      AgendaId: SData._id,
                      agendaInfo:SData
                    }
                    localStorage.setItem(
                      "agenda_id",
                      JSON.stringify(PassingData)
                    )
                  }}
                >
                  Manage Organizer
                </Link>
              </li>
            </ul>
          )
        },
      },
      {
        Header: "Action",
        disableFilters: true,

        Cell: cellProps => {
          const SData = cellProps.row.original
          let sdbtncolor = SData.active_status === "Active"
              ? "btn-soft-danger"
              : "btn-soft-success"
          // console.log(sdbtncolor)
          return (
            <ul className="list-unstyled hstack  gap-1 mb-0">
              <li>
                <Link
                  to="/fetna-agenda-form"
                  className="btn btn-sm btn-soft-info"
                  // style={new Date(SData.end_time) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  // style={{ pointerEvents: 'none', backgroundColor: "#dddddd" }}
                  onClick={e => {
                    const PassingData = {
                      AgendaName: SData.agenda,
                      AgendaId: SData._id,
                      MaxTicket: SData.max_tickets,
                      agendaInfo:SData
                    }
                    localStorage.setItem(
                      "agenda_id",
                      JSON.stringify(PassingData)
                    )
                    //dispatch(editUser(SData))
                    return false
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  {/* <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip> */}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  // style={new Date(SData.end_time) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    tog_backdrop()
                    SetDelete_data({ _id: SData._id })
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  {/* <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip> */}
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Agendas" breadcrumbItem="Manage Agendas" />
          {/* <div>
            {location_id && (
              <div>
                <h2>Date: <span style={{ color: "#556EE6" }}>{moment(location_id.day).format("MMM Do YY")}</span></h2>
              </div>
            )}
          </div> */}
          <Row className="justify-content-center">
            <Card>
              <CardBody className="border-botton">
                <div className="d-flex align-items-center justify-content-between ">
                <div>
												<div style={{margin:0, pading:0}}><label className="text-primary m-0" style={{fontSize:16, lineHeight:1}}>Room: {hallInfo.hallInfo.name}</label></div>
												<div><label className="text-secondary m-0" style={{fontSize:13, lineHeight:1}}>Location: {event_id_c.eventInfo.name}</label></div>
												<div><label className="text-secondary m-0" style={{fontSize:13, lineHeight:1}}>Date: {moment(event_id_c.eventInfo.day).format("ll")}</label></div>
												<div><label className="text-secondary m-0" style={{fontSize:13,  lineHeight:1}}>Event: {event_id_c.eventInfo.event_name}</label></div>
											</div>
                  <div className="mb-1 flex-shrink-0">
                    {/* <ExportExcel
                      excelData={AgendaList}
                      fileName={event_id_c.EventName}
                    /> */}
                    <Link
                      to="#"
                      className="btn btn-primary me-1"
                      onClick={e => {
                        e.preventDefault()
                        navigate("/fetna-agenda-form")
                      }}
                    >
                      Add Agenda
                    </Link>
                    <Link
                      to="#!"
                      className="btn btn-secondary me-1"
                      onClick={e => {
                        e.preventDefault()
                        navigate("/fetna-halls-list")
                      }}
                    >
                      Back
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(fetchAgenda())
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row className="mt-3">
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={AgendaList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={20}
                      isJobListGlobalFilter={false}
                      isColumnFilterEnabled={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
                {AgendaList.length > 10 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>
          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
          >
            {selectedData !== undefined && (
              <>
                <OffcanvasHeader
                  toggle={toggleRightCanvas}
                  className="border-bottom border-secondary"
                >
                  {"Member Information"}
                </OffcanvasHeader>
                <OffcanvasBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                    }}
                  >
                    <Row className="p-3">
                      {/* {selectedData.image_name && (
                        <Col className="col-12 mb-3 justify-content-center align-items-center">
                          <img
                            src={`${APIServerIP}assets/uploads/${selectedData.image_name}`}
                            alt="mi"
                            style={{
                              width: "40%",
                            }}
                          ></img>
                        </Col>
                      )} */}

                      <Col className="col-12 mb-3">
                        <div
                          style={{ fontSize: 13 }}
                          className="text-secondary text-opacity-75"
                        >
                          Description
                        </div>
                        <div style={{ fontSize: 15, fontWeight: 500 }}>
                          {selectedData.description}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </OffcanvasBody>
              </>
            )}
          </Offcanvas>
          {/*delete Modal */}
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Are you sure, you want to delete?</p>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={() => {
                  dispatch(deleteAgenda(delete_data))
                  setmodal_backdrop(false)
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FetnaAgendaList
