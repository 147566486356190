import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const initialHallState = {
  HallList: [],
  HallisSuccessfully: false,
  isUserEdit: false,
}

const HallSlice = createSlice({
  name: "EventHall",
  initialState: initialHallState,
  reducers: {
    setHallList: (state, action) => {
      state.HallList = action.payload
    },
    setHallSuccessfullyAdded: (state, action) => {
      state.HallisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    resetHall: (state, action) => {
      state.isSuccessfullyAdded = false
      state.isUserEdit = false
    },
  },
})

export const createHall = values => {
  return async dispatch => {
    try {
      dispatch(resetHall())
      const responseData = await post(
        `${APIServerIP}fetna-halls/create-fetna-event-hall`,
        values
      )
      // console.log("Hall user", responseData)
      if (responseData.response_code === 500) {
        dispatch(setHallSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateHall = values => {
  return async dispatch => {
    try {
      dispatch(resetHall())
      const responseData = await post(
        `${APIServerIP}fetna-halls/update-fetna-event-hall`,
        values
      )

      if (responseData.response_code === 200) {
        // console.log("Hall user", responseData.state)
        dispatch(setHallSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const deleteHall = id => {
  return async dispatch => {
    try {
      dispatch(resetHall())
      const responseData = await post(
        `${APIServerIP}fetna-halls/delete-fetna-event-hall`,
        id
      )

      if (responseData.response_code === 200) {
        // console.log("OrgansierDetails user", responseData)
        dispatch(setHallSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchHall = data => {
  // console.log("fetchHall", data)
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-halls/fetch-fetna-event-hall`,
        data
        // { master_location_id: id  }
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setHallList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setHallList,
  setHallSuccessfullyAdded,
  resetHall,
  setUserEdit,
} = HallSlice.actions

export default HallSlice.reducer
