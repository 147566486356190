// FetnaUserSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../helpers/api_helper";
import { APIServerIP } from "../../helpers/Sats_api_helper";

const fetchFetnaUserApi = `${APIServerIP}fetna/fetch-fetna-users`;

const initialState = {
  FetnaUserList: [],
  FetnaUserisSuccessfully: false,
  loading: false,
  error: null,
};

// Async thunk action
export const fetchFetnaUser = createAsyncThunk(
  "fetna/fetch-fetna-users",
  async (_, { rejectWithValue }) => {
    try {
      const response = await get(fetchFetnaUserApi);
      if (response.response_code === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.message || 'Failed to fetch users');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch users');
    }
  }
);

// Slice
const FetnaUserSlice = createSlice({
  name: "FetnaUser",
  initialState,
  reducers: {
    resetFetnaUser: (state) => {
      state.FetnaUserisSuccessfully = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFetnaUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFetnaUser.fulfilled, (state, action) => {
        state.FetnaUserList = action.payload;
        state.FetnaUserisSuccessfully = true;
        state.loading = false;
      })
      .addCase(fetchFetnaUser.rejected, (state, action) => {
        state.FetnaUserisSuccessfully = false;
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetFetnaUser } = FetnaUserSlice.actions;
export default FetnaUserSlice.reducer;
