import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../helpers/Sats_api_helper"

const initialDayState = {
  DayList: [],
  volunteers: [],
  TicketBookedDetails: [],
  DayisSuccessfully: false,
  isUserEdit: false,
  scannedUser: null,
  scannedUserStatus: null,
  scannerStatusMessage: null
}

const DaySlice = createSlice({
  name: "DayValue",
  initialState: initialDayState,
  reducers: {
    setDayList: (state, action) => {
      state.DayList = action.payload
    },
    setDaySuccessfullyAdded: (state, action) => {
      state.DayisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    setvolunteerList: (state, action) => {
      state.volunteers = action.payload
    },
    setTicketBookedList: (state, action) => {
      state.TicketBookedDetails = action.payload
      state.DayisSuccessfully = true
    },
    resetDay: (state, action) => {
      state.DayisSuccessfully = false
      state.isUserEdit = false
    },
    ticketScanned: (state, action) => {
      state.scannedUser = action.payload.data
      state.scannedUserStatus = action.payload.message_code
      state.scannerStatusMessage = action.payload.message
    },
    resetScannedData: (state, action) => {
      state.scannedUser = null
      state.scannedUserStatus = null
      state.scannerStatusMessage = null
    },
  },
})

export const createDay = values => {
  return async dispatch => {
    try {
      dispatch(resetDay())
      const responseData = await post(
        `${APIServerIP}fetna-days/create-fetna-event-date`,
        values
      )
      // console.log("Venue user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setDaySuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateDay = values => {
  return async dispatch => {
    try {
      dispatch(resetDay())
      const responseData = await post(
        `${APIServerIP}fetna-days/update-fetna-event-date`,
        values
      )
      // console.log("Venue user", responseData)
      if (responseData.response_code === 200) {
        dispatch(setDaySuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const deleteDay = id => {
  return async dispatch => {
    try {
      dispatch(resetDay())
      const responseData = await post(
        `${APIServerIP}fetna-days/delete-fetna-event-date`,
        id
      )

      if (responseData.response_code === 200) {
        // console.log("Venue user", responseData)
        dispatch(setDaySuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
// export const activeInactiveVenue = data => {
//   return async dispatch => {
//     try {
//       dispatch(resetVenue())
//       const responseData = await post(
//         `${APIServerIP}manage-event-venue/SetactiveInactive-manage-event-venue`,
//         data
//       )

//       if (responseData.response_code === 200) {
//         // console.log("active user", responseData)
//         dispatch(setVenueSuccessfullyAdded())
//       }
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchDay = id => {
  // console.log(id)
  return async dispatch => {
    dispatch(resetDay())
    try {
      let responseData = await post(
        `${APIServerIP}fetna-days/fetch-fetna-event-date`,
        { master_event_id: id  }
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setDayList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const TicketBookedHistory = id => {
  // console.log(id)
  return async dispatch => {
    dispatch(resetDay())
    try {
      let responseData = await post(
        `${APIServerIP}manage-booking-user/fetch-venue-ticket-booking-user`,
        { master_venue_id: id }
      )
      console.log("TicketBookedHistory", responseData)
      if (responseData.response_code === 200) {
        dispatch(setTicketBookedList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const volunteerList = data => {
  return async dispatch => {
    try {
      let responseData = await get(`${APIServerIP}`, data)
      // console.log("volunteers get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setvolunteerList(responseData.data))
      }
    } catch (err) {
      console.log("try error volunteers", err)
    }
  }
}


export const scanningData = data => {
  return async dispatch => {
    try {
      let responseData = await post(`${APIServerIP}manage-booking-user/scan-ticket`, data)
      console.log("responseData", responseData)
      // if (responseData.response_code === 200) {
      dispatch(ticketScanned(responseData))
      // }
    } catch (error) {
      console.log("error", error)
    }
  }
}

export const {
  setDayList,
  setDaySuccessfullyAdded,
  setUserEdit,
  setTicketBookedList,
  resetDay,
  setvolunteerList,
  ticketScanned,
  resetScannedData
} = DaySlice.actions

export default DaySlice.reducer
