import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const initialLocationState = {
  LocationList: null,
  LocationisSuccessfully: false,
  isUserEdit: false,
}

const LocationSlice = createSlice({
  name: "EventLocation",
  initialState: initialLocationState,
  reducers: {
    setLocationList: (state, action) => {
      state.LocationList = action.payload
    },
    setLocationSuccessfullyAdded: (state, action) => {
      state.LocationisSuccessfully = action.payload
    },
    clearLocationSuccessfullyAdded: (state, action) => {
      state.LocationisSuccessfully = false
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    resetLocation: (state, action) => {
      state.isUserEdit = false
    },


  },
})

// export const createLocation = (image, values) => {
//   return async dispatch => {
//     try {
//       const formData = new FormData()
//       formData.append("file", image),
//       formData.append("values", JSON.stringify(values))

//       dispatch(resetLocation())

//       await fetch(`${APIServerIP}fetna-event/create-fetna-event-location`, {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//         },
//         body: formData,
//       })
//       .then(res => {
//         if (res.response_code === 200) {
//           dispatch(setLocationSuccessfullyAdded(true))
//         }
//         else
//         {
//           dispatch(setLocationSuccessfullyAdded(false))
//         }
//       })
//       .catch(err => {
//         console.log("try error", err)
//         dispatch(setLocationSuccessfullyAdded(false))
//       })
//     } catch (error) {
//       console.log("try error", error)
//     }
//   }
// }

export const createLocation = (image, values) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("values", JSON.stringify(values));

      dispatch(resetLocation());

      const response = await fetch(`${APIServerIP}fetna-event/create-fetna-event-location`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      });

      const responseData = await response.json();
      if (responseData.response_code === 200) {
        dispatch(setLocationSuccessfullyAdded(true));
      } else {
        dispatch(setLocationSuccessfullyAdded(false));
      }
    } catch (error) {
      console.log("try error", error);
      dispatch(setLocationSuccessfullyAdded(false));
    }
  };
};


// export const updateLocation = (image, values) => {

//   return async dispatch => {

//     const formData1 = new FormData()
//     formData1.append("file", image),
//       formData1.append("values", JSON.stringify(values))

//     //dispatch(resetLocation())
//     await fetch(`${APIServerIP}fetna-location/update-fetna-event-location`, {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//       },
//       body: formData1,
//     })
//       .then(res => {
//         console.log("res", res)
//         if (res.status == 200) {
//           dispatch(setLocationSuccessfullyAdded(true))
          
//         }
//         else
//         {
//           dispatch(setLocationSuccessfullyAdded(false))
//         }
//       })
//       .catch(err => {
//         console.log("Updatetry error", err)
//         dispatch(setLocationSuccessfullyAdded(false))
//       })
//   }
// }

export const updateLocation = (image, values) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("values", JSON.stringify(values));

      // dispatch(resetLocation());
      const response = await fetch(`${APIServerIP}fetna-location/update-fetna-event-location`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      });

      const responseData = await response.json();
      if (responseData.response_code === 200) {
        dispatch(setLocationSuccessfullyAdded(true));
      } else {
        dispatch(setLocationSuccessfullyAdded(false));
      }
    } catch (error) {
      console.log("Updatetry error", error);
      dispatch(setLocationSuccessfullyAdded(false));
    }
  };
};


export const deleteLocation = id => {
  return async dispatch => {
    try {
      dispatch(resetLocation())
      const responseData = await post(
        `${APIServerIP}fetna-location/delete-fetna-event-location`,
        id
      )

      if (responseData.response_code === 200) {
        // console.log("OrgansierDetails user", responseData)
        dispatch(setLocationSuccessfullyAdded(true))
      }
      else
      {
        dispatch(setLocationSuccessfullyAdded(false))
      }
    } catch (error) {
      console.log("try error", error)
      dispatch(setLocationSuccessfullyAdded(false))
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.removeItem("editUser")
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchLocation = () => {
  return async dispatch => {
    try {
      let responseData = await get(`${APIServerIP}fetna-location/fetch-fetna-event-location`)
      if (responseData.response_code === 200) {
        dispatch(setLocationList(responseData.data))
        dispatch(resetLocation())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setLocationList,
  setLocationSuccessfullyAdded,
  clearLocationSuccessfullyAdded,
  resetLocation,
  setUserEdit,
} = LocationSlice.actions

export default LocationSlice.reducer
