import React, { useRef, useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
/*  Internation phoneinput */
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import {
    Form,
    Input,
    Label,
    Col,
    Row,
    FormFeedback,
    Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
    createHall,
    updateHall,
} from "../../../../toolkitStore/FetnaSlice/HallSlice"

function FetnaHallForm({ toggleRightCanvas }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { HallisSuccessfully, isUserEdit } = useSelector(
        state => state.HallReducer
    )
    const [selectedData, SetSelectData] = useState()

    const event_id_c = JSON.parse(localStorage.getItem("event_id"))
    // console.log("event_id", event_id_c)
    const location_id_c = JSON.parse(localStorage.getItem("location_id"))
    const date_id_c = JSON.parse(localStorage.getItem("date_id"))
    // console.log("venue_id", location_id_c)

    // useEffect(() => {
    //   if (HallisSuccessfully) {
    //     toggleRightCanvas()
    //   }
    // }, [HallisSuccessfully])

    useEffect(() => {
        if (isUserEdit) {
            const editData = JSON.parse(localStorage.getItem("editUser"))
            SetSelectData(editData)
        }
    }, [isUserEdit])

    //Cancel
    const Cancel = e => {
        e.preventDefault()
        localStorage.removeItem("editUser")
        SetSelectData("")
        toggleRightCanvas()
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            master_event_id: selectedData?.master_event_id || event_id_c.EventId,
            master_date_id: selectedData?.date_id_c || event_id_c.DateId,
            master_location_id: selectedData?.master_location_id || (location_id_c ? location_id_c.LocationId : ""),
            name: selectedData?.name || "",
            short_name: selectedData?.short_name || "",
            latitude: selectedData?.latitude || "",
            longitude: selectedData?.longitude || "",
            description: selectedData?.description || "",
            capacity: selectedData?.capacity || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Enter hall name")
        }),
        onSubmit: (values, actions) => {
            {
                if (selectedData?._id) {
                    values["_id"] = selectedData?._id

                    dispatch(updateHall(values))
                    toggleRightCanvas()
                } else {
                    dispatch(createHall(values))
                    toggleRightCanvas()
                }
            }
            actions.resetForm()
        },
    })
    return (
        <>
            <Container fluid>
                <Form
                    onSubmit={event => {
                        event.preventDefault()
                        validation.handleSubmit()
                    }}
                >
                    <Row className="col-12 ">
                        <Col className="col-12 d-flex flex-wrap">
                            <div className="mb-3 col-12">
                                <Label htmlFor="name">Room Name</Label>
                                <Input
                                    name="name"
                                    className="form-control"
                                    placeholder="Enter Room Name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={
                                        validation.touched.name && validation.errors.name
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.name}
                                    </FormFeedback>
                                ) : <label className="my-2 text-secondary text-opacity-75">Example : Conference Room, Meeting Room, Ballroom, Exhibit Hall</label>}
                            </div>

                            <div className="mb-3 col-12 ">
                                <Label htmlFor="short_name">Room Short Name</Label>
                                {/* <PhoneInput
                                    type="tel"
                                    id="contact_number"
                                    name="contact_number"
                                    className="form-group"
                                    value={validation.values.contact_number}
                                    onChange={value =>
                                        validation.setFieldValue("contact_number", value)
                                    }
                                    onBlur={validation.handleBlur}
                                    countryCallingCodeEditable={true}
                                    inv={
                                        validation.touched.contact_number &&
                                        validation.errors.contact_number
                                        ? true
                                        : false
                                    }
                                    />
                                    {validation.touched.contact_number &&
                                    validation.errors.contact_number && (
                                        <div className="error" style={{ fontSize: "12px" }}>
                                        {validation.errors.contact_number}
                                        </div>
                                    )} */}
                                <Input
                                    name="short_name"
                                    className="form-control"
                                    placeholder="Enter Room Short Name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.short_name || ""}
                                    invalid={
                                        validation.touched.short_name &&
                                            validation.errors.short_name
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.short_name &&
                                    validation.errors.short_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.short_name}
                                    </FormFeedback>
                                ) : null}
                            </div>

                            <Col className="col-12 d-flex flex-wrap justify-content-between">
                                
                                <div className="mb-3 col-12 ">
                                    <Label htmlFor="capacity">Room Capacity</Label>
                                    <Input
                                        name="capacity"
                                        className="form-control"
                                        placeholder="Enter capacity"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.capacity || ""}
                                        invalid={
                                            validation.touched.capacity && validation.errors.capacity
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.capacity && validation.errors.capacity ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.capacity}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <div className="mb-3 col-12">
                                    <Label htmlFor="description"> Description</Label>
                                    <Input
                                        name="description"
                                        className="form-control"
                                        placeholder="Enter description"
                                        type="textarea"
                                        style={{height:150}}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""}
                                        invalid={
                                            validation.touched.description && validation.errors.description
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.description}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            <Col className="col-12 d-flex flex-wrap justify-content-between">
                                <div className="mb-3 col-12">
                                    <Label htmlFor="latitude"> Latitude</Label>
                                    <Input
                                        name="latitude"
                                        className="form-control"
                                        placeholder="Enter Latitude"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.latitude || ""}
                                        invalid={
                                            validation.touched.latitude && validation.errors.latitude
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.latitude && validation.errors.latitude ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.latitude}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3 col-12 ">
                                    <Label htmlFor="longitude"> Longitude</Label>
                                    <Input
                                        name="longitude"
                                        className="form-control"
                                        placeholder="Enter Longitude"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.longitude || ""}
                                        invalid={
                                            validation.touched.longitude && validation.errors.longitude
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.longitude && validation.errors.longitude ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.longitude}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            
                            {/* <div className="mb-3 col-12">
                        <Label htmlFor="venue_status"> Active Status </Label>
                        <select
                          id="venue_status"
                          name="venue_status"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.venue_status}
                        >
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </select>
                      </div> */}
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <div className=" text-center mt-3 ">
                                <button type="submit" className="btn btn-success w-md px-5">
                                    {selectedData?._id ?"Update" : "Submit"}
                                </button>
                            </div>
                            <div className=" text-center mt-3 mx-3 ">
                                <button
                                    type="button"
                                    className="btn btn-danger w-md px-5"
                                    onClick={Cancel}
                                >
                                    {"Cancel"}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default FetnaHallForm
