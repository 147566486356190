import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { useSelector, useDispatch } from "react-redux"
import {
    Alert,
    CardBody,
    Card,
    Row,
    Col,
    CardTitle,
    Badge,
    Modal,
    UncontrolledTooltip,
    Button,
    Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//import components
import TableContainer from "components/Common/TableContainer"
// slice

import FetnaDayForm from "./FetnaDayForm"

import { EditSeats, UpdateSeats } from "toolkitStore/SeatArrangement/SeatSlice"
import {
    fetchDay,
    editUser,
    deleteDay,
    //   activeInactiveVenue,
} from "../../../../toolkitStore/FetnaSlice/DaySlice"

function DayList(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { DayList, DayisSuccessfully } = useSelector(
        state => state.DayReducer
    )
    const [modal_backdrop, setmodal_backdrop] = useState(false)
    const [modal_backdrop1, setmodal_backdrop1] = useState(false)
    const [delete_data, SetDelete_data] = useState()
    const [dataMode, setDataMode] = useState("New")
    const event_id_c = JSON.parse(localStorage.getItem("event_id"))


    function tog_backdrop1() {
        setmodal_backdrop1(!modal_backdrop1);
        removeBodyCss();
    }

    useEffect(() => {
        if (DayList) {
            dispatch(fetchDay(event_id_c.EventId))
        }
    }, [setmodal_backdrop, DayisSuccessfully])

    useEffect(() => {
        localStorage.removeItem("venue_id")
        dispatch(fetchDay(event_id_c.EventId))
    }, [])

    // console.log(VenueList)
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop)
        removeBodyCss()
    }

    const columns = useMemo(
        () => [
            {
                Header: "Day",
                accessor: "day",
                disableFilters: true,
            },
            {
                Header: "Date",
                accessor: "date",
                disableFilters: true,
                Cell: cellProps => {
                    let sd = moment
                        .parseZone(cellProps.row.original.date)
                        .format("MMMM Do YYYY")
                    return (
                        <ul className="list-unstyled">
                            <p>{sd}</p>
                        </ul>
                    )
                },
            },
            {
                Header: "Action",
                disableFilters: true,

                Cell: cellProps => {
                    const SData = cellProps.row.original
                    let sdbtncolor = SData.active_status === "Active"
                        ? "btn-soft-danger"
                        : "btn-soft-success"
                    // console.log(sdbtncolor)
                    return (
                        <ul className="list-unstyled hstack  gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    style={new Date(SData.date) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                                    onClick={e => {
                                        e.preventDefault()
                                        const PassingData = {
                                            // VenueName: SData.venue,
                                            VenueId: SData._id,
                                            // MaxTicket: SData.max_tickets,
                                            date: SData.date,
                                            scheduleInfo:SData
                                        }
                                        localStorage.setItem(
                                            "venue_id",
                                            JSON.stringify(PassingData)
                                        )
                                        SetDelete_data(PassingData)
                                        setDataMode("Edit")
                                        tog_backdrop1()
                                        // dispatch(editUser(SData))
                                        return false
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    {/* <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip> */}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    style={new Date(SData.date) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                                    onClick={e => {
                                        e.preventDefault()
                                        const SData = cellProps.row.original
                                        // console.log("deleteID", SData._id)
                                        tog_backdrop()
                                        SetDelete_data({ _id: SData._id })
                                        return false
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    {/* <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip> */}
                                </Link>
                            </li>
                            {/* <li>
                                <Link
                                    to="#!"
                                    className={`badge btn ${sdbtncolor} p-2`}
                                    style={new Date(SData.end_datetime) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                                    id="active"
                                    onClick={e => {
                                        e.preventDefault()
                                        const SData = cellProps.row.original
                                        let PassingData = {
                                            VenueId: SData._id,
                                            status: SData.active_status,
                                        }

                                        dispatch(activeInactiveVenue(PassingData))
                                        dispatch(fetchVenue(event_id_c.EventId))
                                    }}
                                >
                                    {cellProps.row.original.active_status === "Active"
                                        ? "Set Inactive"
                                        : "Set Active"}
                                </Link>
                            </li> */}
                        </ul>
                    )
                },
            },
        ],
        []
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Fetna Days" breadcrumbItem="Schedule" />
                    <Row className="justify-content-center">
                        <Card>
                            <CardBody className="border-botton">
                                <div className="d-flex align-items-center ">
                                    <h5 className="mb-1 card-title flex-grow-1 text-primary">
                                        {event_id_c.EventName}
                                    </h5>
                                    <div className="mb-1 flex-shrink-0">
                                        
                                        <Link
                                            to="#"
                                            className="btn btn-primary me-1"
                                            onClick={e => {
                                                e.preventDefault()
                                                setDataMode("New")
                                                localStorage.removeItem("venue_id")
                                                tog_backdrop1()
                                                //navigate("/fetna-day-form")
                                            }}
                                        >
                                            Schedule on
                                        </Link>
                                        <Link
                                            to="#!"
                                            className="btn btn-secondary me-1"
                                            onClick={e => {
                                                e.preventDefault()
                                                navigate("/fetna-event-list")
                                            }}
                                        >
                                            Back
                                        </Link>
                                        <Link
                                            to="#!"
                                            onClick={() => {
                                                dispatch(fetchDay(event_id_c.EventId))
                                            }}
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                                <Row>
                                    <div className="container-fluid">
                                        <TableContainer
                                            columns={columns}
                                            data={DayList}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            customPageSize={20}
                                            isJobListGlobalFilter={false}
                                            isColumnFilterEnabled={false}
                                        // className="custom-header-css"
                                        />
                                    </div>
                                </Row>
                                {DayList.length > 10 ? (
                                    <Row>
                                        <Col xs="12">
                                            <div className="text-center my-3">
                                                <Link to="#" className="text-success">
                                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                                    Load more
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Row>
                    {/*delete Modal */}
                    <Modal
                        isOpen={modal_backdrop1}
                        toggle={() => {
                            tog_backdrop1();
                        }}
                        backdrop={'static'}
                        id="staticBackdrop"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Event Schedule</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setmodal_backdrop1(false);
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <FetnaDayForm
                                onClick={() => {
                                    setmodal_backdrop1(false);
                                }}
                            />
                        </div>

                    </Modal>
                    <Modal
                        isOpen={modal_backdrop}
                        toggle={() => {
                            tog_backdrop()
                        }}
                        backdrop={"static"}
                        id="staticBackdrop"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Delete
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setmodal_backdrop(false)
                                }}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body font-size-15">
                            <p>Are you sure, you want to delete?</p>
                        </div>
                        <div className="modal-footer ">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                onClick={() => {
                                    dispatch(deleteDay(delete_data))
                                    setmodal_backdrop(false)
                                    // dispatch(fetchVenue(event_id_c.EventId))
                                }}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    setmodal_backdrop(false)
                                }}
                            >
                                No
                            </button>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DayList
