import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { useSelector, useDispatch } from "react-redux"
import {
  Alert,
  CardBody,
  Card,
  Row,
  Col,
  CardTitle,
  Badge,
  Modal,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Button,
  Container,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//import components
import TableContainer from "components/Common/TableContainer"
// slice

import { EditSeats, UpdateSeats } from "toolkitStore/SeatArrangement/SeatSlice"
import {
  fetchLocation,
  editUser,
  deleteLocation,
  activeInactiveLocation,
  clearLocationSuccessfullyAdded
} from "../../../../toolkitStore/FetnaSlice/LocationSlice"

function FetnaLocationList(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //Delete
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [delete_data, SetDelete_data] = useState()
  const event_id_c = JSON.parse(localStorage.getItem("event_id"))
  const [selectedData, SetSelectData] = useState()

  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  const { LocationList, LocationisSuccessfully } = useSelector(
    state => state.LocationReducer
  )

  useEffect(() => {
    if (LocationList) {
      dispatch(clearLocationSuccessfullyAdded())
      dispatch(fetchLocation())
    }
  }, [setmodal_backdrop, LocationisSuccessfully])

  useEffect(() => {
    // console.log("LocationisSuccessfully", LocationisSuccessfully)
    localStorage.removeItem("editUser")
    localStorage.removeItem("location_id")
    dispatch(fetchLocation())
    return() => {}
  }, [])

  // console.log(LocationList)
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Location Name",
        accessor: "name",
        disableFilters: true,
      },

      {
        Header: "Day",
        accessor: "day",
        disableFilters: true,
        Cell: cellProps => {
          let sd = moment
            .parseZone(cellProps.row.original.day)
            .format("MMMM Do YYYY")
          return (
            <ul className="list-unstyled">
              <p>{sd}</p>
            </ul>
          )
        },
      },

      {
        Header: "Event Name",
        accessor: "event_name",
        disableFilters: true,
      },
      
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
      },
      {
        Header: "Manage Room",
        disableFilters: true,
        Cell: cellProps => {
          const SData = cellProps.row.original
          return (
            <ul className="list-unstyled hstack  gap-3 mb-0">
              <li>
                <Link
                  to="/fetna-halls-list"
                  className="btn btn-sm btn-primary"
                  style={new Date(SData.end_datetime) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    const PassingData = {
                      LocationName: SData.location,
                      LocationId: SData._id,
                    }
                    localStorage.setItem(
                      "location_id",
                      JSON.stringify(PassingData)
                    )
                    localStorage.setItem(
                      "event_id",
                      JSON.stringify({
                        EventId: SData.master_event_id,
                        DateId: SData.master_date_id,
                        eventInfo:SData
                      })
                    )
                  }}
                >
                  Manage Room
                </Link>
              </li>
            </ul>
          )
        },
      },
      {
        Header: "Action",
        disableFilters: true,

        Cell: cellProps => {
          const SData = cellProps.row.original
          let sdbtncolor = SData.active_status === "Active"
            ? "btn-soft-danger"
            : "btn-soft-success"
          // console.log(sdbtncolor)
          return (
            <ul className="list-unstyled hstack  gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  style={new Date(SData.day) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={ e => {
                    e.preventDefault()
                    const PassingData = {
                      LocationName: SData.location,
                      LocationId: SData._id,
                      // MaxTicket: SData.max_tickets,
                    }
                    localStorage.removeItem("editUser")
                    localStorage.setItem(
                      "location_id",
                      JSON.stringify(PassingData)
                    )
                    dispatch(editUser(SData))
                    navigate("/fetna-location-form")
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  {/* <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip> */}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  style={new Date(SData.day) < new Date() ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                  onClick={e => {
                    e.preventDefault()
                    const SData = cellProps.row.original
                    // console.log("deleteID", SData._id)
                    SetDelete_data({ _id: SData._id })
                    tog_backdrop()
                    return false
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    [navigate]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Locations" breadcrumbItem="Locations" />
          <Row className="justify-content-center">
            <Card>
              <CardBody className="border-botton">
                <div className="d-flex align-items-center ">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    {/* {event_id_c.EventName} */}
                  </h5>
                  <div className="mb-1 flex-shrink-0">
                    {/* <ExportExcel
                      excelData={LocationList}
                      fileName={event_id_c.EventName}
                    /> */}
                    <Link
                      to="#"
                      className="btn btn-primary me-1"
                      onClick={e => {
                        e.preventDefault()
                        localStorage.removeItem("editUser")
                        navigate("/fetna-location-form")
                      }}
                    >
                      Add Location
                    </Link>
                    
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(fetchLocation())
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                {
                  LocationList && <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={LocationList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={20}
                      isJobListGlobalFilter={false}
                      isColumnFilterEnabled={false}
                    // className="custom-header-css"
                    />
                  </div>
                </Row>
                }
                
                <Offcanvas
                  isOpen={isRight}
                  direction="end"
                  toggle={toggleRightCanvas}
                >
                  {selectedData !== undefined && (
                    <>
                      <OffcanvasHeader
                        toggle={toggleRightCanvas}
                        className="border-bottom border-secondary"
                      >
                        {"Member Information"}
                      </OffcanvasHeader>
                      <OffcanvasBody>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                          }}
                        >
                          <Row className="p-3">
                            <Col className="col-12 mb-3">
                              <div
                                style={{ fontSize: 13 }}
                                className="text-secondary text-opacity-75"
                              >
                                Description
                              </div>
                              <div style={{ fontSize: 15, fontWeight: 500 }}>
                                {selectedData.description}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </OffcanvasBody>
                    </>
                  )}
                </Offcanvas>
                {LocationList && LocationList.length > 10 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>
          {/*delete Modal */}
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-size-15">
              <p>Are you sure, you want to delete?</p>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={() => {
                  dispatch(deleteLocation(delete_data))
                  setmodal_backdrop(false)
                  // dispatch(fetchLocation(event_id_c.EventId))
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
              >
                No
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FetnaLocationList
