import React, { useMemo, useState, useEffect } from "react";
import ExportExcel from "components/Excelconvert/ExportExcel";
import { useSelector, useDispatch } from "react-redux";
import {
  CardBody,
  Card,
  Row,
  Col,
  Modal,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
  Button,
  Container,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

// Import components
import TableContainer from "components/Common/TableContainer";

// Slice
import {
  resetFetnaEvent,
  editUser,
  fetchFetnaEvent,
  deleteFetnaEvent,
  activeInactiveEvent,
} from "../../../toolkitStore/FetnaSlice/fetnaSlice";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

function FetnaEventList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [delete_data, SetDelete_data] = useState();
  const [modalFor, SetmodalFor] = useState();
  const [selectedData, SetSelectData] = useState();

  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const { newFetnaEventList, isSuccessfullyAdded } = useSelector(
    (state) => state.FetnaReducer
  );

  useEffect(() => {
    if (isSuccessfullyAdded) {
      dispatch(fetchFetnaEvent());
    }
    dispatch(resetFetnaEvent());
  }, [modal_backdrop, isSuccessfullyAdded]);
  
  useEffect(() => {
    localStorage.removeItem("event_id");
    localStorage.removeItem("venue_id");
    dispatch(fetchFetnaEvent());
  }, [dispatch]);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  const CloseandCancel = () => {
    tog_backdrop();
    SetDelete_data();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Event Name",
        accessor: "name" || "event_name",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="" style={{ fontWeight: 600 }}>
              {cellProps.row.original.name}
            </div>
          );
        },
      },
      {
        Header: "Event Schedule",
        filter: false,
        Cell: (cellProps) => {
          const SData = cellProps.row.original;
          return (
            <Link
              to="/fetna-day-list"
              className="btn btn-sm btn-primary"
              onClick={() => {
                let PassingData = {
                  EventName: SData.name,
                  EventId: SData._id,
                  EventDay: SData.event_date,
                };
                localStorage.setItem("event_id", JSON.stringify(PassingData));
              }}
              id="venuetooltip"
            >
              Schedule
            </Link>
          );
        },
      },
      {
        Header: "Manage Organizers",
        accessor: "organiser",
        Cell: (cellProps) => {
          const SData = cellProps.row.original;
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to={`/fetna-event-organiser-form`}
                  className="btn btn-sm btn-primary"
                  style={
                    SData.active_status === "Inactive"
                      ? { pointerEvents: "none", backgroundColor: "#dddddd" }
                      : {}
                  }
                  onClick={() => {
                    let PassingData = {
                      EventName: SData.name,
                      Description: SData.description,
                      ImageName: SData.image_name,
                      EventId: SData._id,
                      eventInfo: SData
                    };
                    localStorage.setItem(
                      "event_id",
                      JSON.stringify(PassingData)
                    );
                    // dispatch(editUser(SData));
                    return false;
                  }}
                >
                  Manage Organizers
                </Link>
              </li>
            </ul>
          );
        },
      },
      // {
      //   Header: "Description",
      //   accessor: "description",
      //   Cell: (cellProps) => {
      //     return (
      //       <ul className="list-unstyled mb-0">
      //         <li>
      //           <Link
      //             to="#"
      //             className="btn btn-sm btn-soft-info me-2"
      //             onClick={(e) => {
      //               const SData = cellProps.row.original;
      //               localStorage.setItem(
      //                 "editMember",
      //                 JSON.stringify(SData._id)
      //               );
      //               SetSelectData(SData);
      //               toggleRightCanvas();
      //               return false;
      //             }}
      //           >
      //             Description <i className="mdi mdi-book-outline" id="edittooltip" />
      //           </Link>
      //         </li>
      //       </ul>
      //     );
      //   },
      // },
      
      {
        Header: "Action",
        filter: false,
        Cell: (cellProps) => {
          const SData = cellProps.row.original;
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="/fetna-event-form"
                  className="btn btn-sm btn-soft-info"
                  style={
                    SData.active_status === "Inactive"
                      ? { pointerEvents: "none", backgroundColor: "#dddddd" }
                      : {}
                  }
                  onClick={() => {
                    let PassingData = {
                      EventName: SData.name,
                      EventId: SData._id,
                      eventInfo:SData
                    };
                    localStorage.setItem(
                      "event_id",
                      JSON.stringify(PassingData)
                    );
                    //dispatch(editUser(SData));
                    return false;
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  {/* <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip> */}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  style={
                    SData.active_status === "Inactive"
                      ? { pointerEvents: "none", backgroundColor: "#dddddd" }
                      : {}
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    tog_backdrop();
                    SetDelete_data(SData);
                    SetmodalFor("Delete");
                    return false;
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Event Management"
            breadcrumbItem="Event Management"
          />

          <Row className="justify-content-center">
            <Card>
              <CardBody className="">
                <div className="d-flex justify-content-end">
                  <div className="mb-1 flex-shrink-0">
                    

                    <Link
                      to="/fetna-event-form"
                      onClick={(e) => {
                        // console.log("New Event Open")
                      }}
                      className="btn btn-primary me-1"
                    >
                      Create Event
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(fetchFetnaEvent());
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={newFetnaEventList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={30}
                      isJobListGlobalFilter={false}
                      isShowPageSize={false}
                      isColumnFilterEnabled={false}
                    />
                  </div>
                </Row>
                <Offcanvas
                  isOpen={isRight}
                  direction="end"
                  toggle={toggleRightCanvas}
                >
                  {selectedData !== undefined && (
                    <>
                      <OffcanvasHeader
                        toggle={toggleRightCanvas}
                        className="border-bottom border-secondary"
                      >
                        {"Member Information"}
                      </OffcanvasHeader>
                      <OffcanvasBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                          }}
                        >
                          <Row className="p-3">
                            <Col className="col-12 mb-3">
                              <div
                                style={{ fontSize: 13 }}
                                className="text-secondary text-opacity-75"
                              >
                                Description
                              </div>
                              <div style={{ fontSize: 15, fontWeight: 500 }}>
                                {selectedData.description}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </OffcanvasBody>
                    </>
                  )}
                </Offcanvas>
                {newFetnaEventList && newFetnaEventList.length > 7 ? (
                  <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-primary">
                          <i className="mdi mdi-loading mdi-spin font-size-20 align-middle me-2" />
                          Load more
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Row>

          <div>
            <Modal
              isOpen={modal_backdrop}
              toggle={tog_backdrop}
              backdrop={"static"}
              id="staticBackdrop"
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {modalFor}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={CloseandCancel}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body font-size-15">
                <p>Do you want {modalFor} this Event ?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    if (modalFor === "Delete") {
                      dispatch(deleteFetnaEvent(delete_data));
                    }
                    tog_backdrop();
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={CloseandCancel}
                >
                  No
                </button>
              </div>
            </Modal>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default FetnaEventList;
