import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { del, get, post, put } from "../../helpers/api_helper"
import { APIServerIP } from "../../../src/helpers/Sats_api_helper"

const initialAgendaOrganiserState = {
  AgendaOrganiserList: [],
  AgendaOrganiserisSuccessfully: false,
  isUserEdit: false,
}

const AgendaOrganiserSlice = createSlice({
  name: "AgendaOrganiser",
  initialState: initialAgendaOrganiserState,
  reducers: {
    setAgendaOrganiserList: (state, action) => {
      state.AgendaOrganiserList = action.payload
    },
    setAgendaOrganiserSuccessfullyAdded: (state, action) => {
      state.AgendaOrganiserisSuccessfully = true
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    resetAgendaOrganiser: (state, action) => {
      state.isSuccessfullyAdded = false
      state.isUserEdit = false
    },
  },
})

export const createAgendaOrganiser = values => {
  return async dispatch => {
    try {
      dispatch(resetAgendaOrganiser())
      const responseData = await post(
        `${APIServerIP}agenda-organiser/create-agenda-organiser`,
        values
      )
      // console.log("AgendaOrganiser user", responseData)
      if (responseData.response_code === 500) {
        dispatch(setAgendaOrganiserSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const updateAgendaOrganiser = values => {
  return async dispatch => {
    try {
      dispatch(resetAgendaOrganiser())
      const responseData = await post(
        `${APIServerIP}agenda-organiser/update-agenda-organiser`,
        values
      )

      if (responseData.response_code === 200) {
        // console.log("AgendaOrganiser user", responseData.state)
        dispatch(setAgendaOrganiserSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const deleteAgendaOrganiser = id => {
  return async dispatch => {
    try {
      dispatch(resetAgendaOrganiser())
      const responseData = await post(
        `${APIServerIP}agenda-organiser/delete-agenda-organiser`,
        id
      )

      if (responseData.response_code === 200) {
        // console.log("OrgansierDetails user", responseData)
        dispatch(setAgendaOrganiserSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchAgendaOrganiser = data => {
  // console.log("fetchAgendaOrganiser", data)
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}agenda-organiser/fetch-agenda-organisers`,
        data
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setAgendaOrganiserList(responseData.data))
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const {
  setAgendaOrganiserList,
  setAgendaOrganiserSuccessfullyAdded,
  resetAgendaOrganiser,
  setUserEdit,
} = AgendaOrganiserSlice.actions

export default AgendaOrganiserSlice.reducer
