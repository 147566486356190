import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    CardBody,
    Card,
    Row,
    Col,
    Modal,
    Form,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    UncontrolledTooltip,
    Button,
    Container,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "components/Common/TableContainer";
import ExportExcel from "components/Excelconvert/ExportExcel";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import {
    resetAttraction,
    editUser,
    fetchAttraction,
    deleteAttraction
} from "../../../../toolkitStore/FetnaSlice/AttractionSlice";

function AttractionsList(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [modal_backdrop, setModalBackdrop] = useState(false);
    const [delete_data, setDeleteData] = useState();
    const [modalFor, setModalFor] = useState();
    const [selectedData, setSelectedData] = useState();

    const [isRight, setIsRight] = useState(false);
    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const { newAttractionList, isSuccessfullyAdded } = useSelector(
        state => state.AttractionReducer
    );

    useEffect(() => {
        if (isSuccessfullyAdded) {
            dispatch(fetchAttraction());
        }
        dispatch(resetAttraction());
    }, [isSuccessfullyAdded]);

    useEffect(() => {
        localStorage.removeItem("event_id");
        localStorage.removeItem("venue_id");
        dispatch(fetchAttraction());
    }, []);

    function togBackdrop() {
        setModalBackdrop(!modal_backdrop);
    }

    const closeAndCancel = () => {
        togBackdrop();
        setDeleteData();
    };

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ row }) => (
                    <div style={{ fontWeight: 600 }}>
                        {row.original.name}
                    </div>
                ),
            },
            {
                Header: "URL",
                accessor: "url",
                Cell: ({ row }) => (
                    <div style={{ fontWeight: 600 }}>
                        {row.original.url}
                    </div>
                ),
            },
            // {
            //     Header: "Description",
            //     accessor: "description",
            //     Cell: ({ row }) => (
            //         <ul className="list-unstyled mb-0">
            //             <li>
            //                 <Link
            //                     to="#"
            //                     className="btn btn-sm btn-soft-info me-2"
            //                     onClick={() => {
            //                         const SData = row.original;
            //                         localStorage.setItem(
            //                             "editMember",
            //                             JSON.stringify(SData._id)
            //                         );
            //                         setSelectedData(SData);
            //                         toggleRightCanvas();
            //                         return false;
            //                     }}
            //                 >
            //                     Description <i className="mdi mdi-book-outline" id="edittooltip" />
            //                 </Link>
            //             </li>
            //         </ul>
            //     ),
            // },
            {
                Header: "Action",
                Cell: ({ row }) => {
                    const SData = row.original;
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="/sa-attractions-form"
                                    className="btn btn-sm btn-soft-info"
                                    style={SData.active_status === 'Inactive' ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                                    onClick={() => {
                                        let PassingData = {
                                            EventName: SData.name,
                                            EventId: SData._id,
                                            saAttractivesInfo:SData
                                        };
                                        localStorage.setItem("@saattractiveInfo", JSON.stringify(PassingData));
                                        // dispatch(editUser(SData));
                                        return false;
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    {/* <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip> */}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    style={SData.active_status === 'Inactive' ? { pointerEvents: 'none', backgroundColor: "#dddddd" } : {}}
                                    onClick={e => {
                                        e.preventDefault();
                                        togBackdrop();
                                        setDeleteData(SData);
                                        setModalFor("Delete");
                                        return false;
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    {/* <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip> */}
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        [navigate]
    );

    console.log(newAttractionList);  // Debugging line to check the data structure

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Attraction Management" breadcrumbItem="San Antonio attractions" />
                    <Row className="justify-content-center">
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-end">
                                    <div className="mb-1 flex-shrink-0">
                                        {/* <ExportExcel excelData={newAttractionList} fileName={"Event"} /> */}
                                        <Link
                                            to="#"
                                            className="btn btn-primary me-1"
                                            onClick={(e)=>{
                                                e.preventDefault()
                                                localStorage.removeItem("@saattractiveInfo")
                                                navigate("/sa-attractions-form")
                                            }}
                                        >
                                            Add New Attraction
                                        </Link>
                                        <Link
                                            to="#!"
                                            onClick={() => {
                                                dispatch(fetchAttraction())
                                            }}
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>
                                <Row>
                                    <div className="container-fluid">
                                        {newAttractionList && newAttractionList.length > 0 ? (
                                            <TableContainer
                                                columns={columns}
                                                data={newAttractionList}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                customPageSize={30}
                                                isJobListGlobalFilter={false}
                                                isShowPageSize={false}
                                                isColumnFilterEnabled={false}
                                            />
                                        ) : (
                                            <div>No data available</div>
                                        )}
                                    </div>
                                </Row>
                                <Offcanvas isOpen={isRight} direction="end" toggle={toggleRightCanvas}>
                                    {selectedData && (
                                        <>
                                            <OffcanvasHeader toggle={toggleRightCanvas} className="border-bottom border-secondary">
                                                Member Information
                                            </OffcanvasHeader>
                                            <OffcanvasBody>
                                                <Form>
                                                    <Row className="p-3">
                                                        <Col className="col-12 mb-3">
                                                            <div style={{ fontSize: 13 }} className="text-secondary text-opacity-75">
                                                                Description
                                                            </div>
                                                            <div style={{ fontSize: 15, fontWeight: 500 }}>
                                                                {selectedData.description}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </OffcanvasBody>
                                        </>
                                    )}
                                </Offcanvas>
                                {newAttractionList && newAttractionList.length > 7 && (
                                    <Row>
                                        <Col xs="12">
                                            <div className="text-center my-3">
                                                <Link to="#" className="text-success">
                                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                                    Load more
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Row>
                    <Modal isOpen={modal_backdrop} toggle={togBackdrop} backdrop="static" id="staticBackdrop">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                {modalFor}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeAndCancel}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body font-size-15">
                            <p>Do you want {modalFor} this Attraction?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => {
                                    if (modalFor === "Delete") {
                                        dispatch(deleteAttraction(delete_data._id));
                                    }
                                    togBackdrop();
                                }}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={closeAndCancel}
                            >
                                No
                            </button>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default AttractionsList;
