import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, post, put } from "../../helpers/api_helper";
import { APIServerIP } from "../../../src/helpers/Sats_api_helper";

const initialAgendaState = {
  AgendaList: [],
  configList: [],
  AgendaisSuccessfully: false,
  ConfigisSuccessfully: false,
  isUserEdit: false,
  errorMessage: null,  // Add this line
};

const AgendaSlice = createSlice({
  name: "EventAgenda",
  initialState: initialAgendaState,
  reducers: {
    setAgendaList: (state, action) => {
      state.AgendaList = action.payload;
    },
    setConfigList: (state, action) => {
      state.configList = action.payload;
    },
    setAgendaSuccessfullyAdded: (state, action) => {
      state.AgendaisSuccessfully = true;
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true;
    },
    resetAgenda: (state, action) => {
      state.AgendaisSuccessfully = false;
      state.isUserEdit = false;
    },
    resetConfig: (state, action) => {
      state.ConfigisSuccessfully = false;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = null;
    },
  },
});


export const fetchConfigs = () => {
  return async (dispatch) => {
    dispatch(resetConfig());
    try {
      const responseData = await get(
        `${APIServerIP}fetna-agendas/fetch-fetna-configs`
      );
      if (responseData.response_code === 200) {
        dispatch(setConfigList(responseData.data));
      } else {
        dispatch(setErrorMessage('Failed to fetch configs.'));
      }
    } catch (error) {
      console.log("try error fetch", error);
      dispatch(setErrorMessage('An unexpected error occurred. Please try again.'));
    }
  };
};

export const createAgenda = (image, hostImage, values) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("file", image)
    formData.append("file", hostImage)
    formData.append("values", JSON.stringify(values))

    dispatch(resetAgenda())
    dispatch(clearErrorMessage())
    await fetch(`${APIServerIP}fetna-agendas/create-fetna-agendas`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    }).then(res => res.json())
      .then(res => {
        console.log("res", res)

        if (res.response_code === 200) {
          dispatch(setAgendaSuccessfullyAdded());
        } else if (res.response_code === 504) {
          dispatch(setErrorMessage('This time slot is filled, please schedule another time.'));
        }
      })
      .catch(err => {
        console.log("try error", err)
      })
  }
}

// export const updateAgenda = (image, values) => {

//   return async dispatch => {

//     const formData = new FormData()
//     for (let index = 0; index < image.length; index++) {
//       const element = image[index];
//       formData.append("file", element)
//     }
//     formData.append("values", JSON.stringify(values))

//     // dispatch(resetAgenda())
//     await fetch(`${APIServerIP}fetna-agendas/update-fetna-agendas`, {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//       },
//       body: formData,
//     })
//       .then(res => {
//         if (res.status == 200) {
//           dispatch(setAgendaSuccessfullyAdded())
//           localStorage.removeItem("editUser")
//         }
//       })
//       .catch(err => {
//         console.log("Updatetry error", err)
//       })
//   }
// }

export const updateAgenda = (image, hostImage, values) => {
  return async (dispatch) => {
    // const formData = new FormData();

    // // Append each image in the images array to formData
    // if (images && images.length > 0) {
    //   images.forEach((image) => {
    //     formData.append("file", image);
    //   });
    // }
    const formData = new FormData()
    formData.append("file", image)
    formData.append("file", hostImage)
    formData.append("values", JSON.stringify(values))
    dispatch(clearErrorMessage())

    // formData.append("values", JSON.stringify(values));

    try {
      const response = await fetch(
        `${APIServerIP}fetna-agendas/update-fetna-agendas`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Update response:", data);

        if (data.response_code === 200) {
          dispatch(setAgendaSuccessfullyAdded());
          localStorage.removeItem("editUser");
        } else if (data.response_code === 504) {
          dispatch(
            setErrorMessage(
              "This time slot is filled, please schedule another time."
            )
          );
        }
      } else {
        throw new Error("Failed to update agenda");
      }
    } catch (error) {
      console.error("Update try error:", error);
    }
  };
};


export const deleteAgenda = id => {
  return async dispatch => {
    try {
      dispatch(resetAgenda())
      const responseData = await post(
        `${APIServerIP}fetna-agendas/delete-fetna-agenda`,
        id
      )

      if (responseData.response_code === 200) {
        console.log("Agenda user", responseData)
        dispatch(setAgendaSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveAgenda = data => {
  return async dispatch => {
    try {
      dispatch(resetAgenda())
      const responseData = await post(
        `${APIServerIP}fetna-agendas/SetactiveInactive-manage-event-venue`,
        data
      )

      if (responseData.response_code === 200) {
        // console.log("active user", responseData)
        dispatch(setAgendaSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchAgenda = (id) => {
  return async (dispatch) => {
    dispatch(resetAgenda());
    try {
      const responseData = await post(
        `${APIServerIP}fetna-agendas/fetch-fetna-agenda`,
        { master_hall_id: id.master_hall_id }
      );
      if (responseData.response_code === 200) {
        dispatch(setAgendaList(responseData.data));
      }
    } catch (error) {
      console.log("try error fetch", error);
    }
  };
};


export const {
  setAgendaList,
  setConfigList,
  setAgendaSuccessfullyAdded,
  setUserEdit,
  resetAgenda,
  resetConfig,
  setErrorMessage,
  clearErrorMessage
} = AgendaSlice.actions

export default AgendaSlice.reducer
