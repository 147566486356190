// FetnaUsers.js
import React, { useEffect, useMemo } from "react"
import TableContainer from "components/Common/TableContainer"
import ExportExcel from "components/Excelconvert/ExportExcel"
import { CardBody, Card, Row, Container } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { fetchFetnaUser } from "../../../toolkitStore/FetnaSlice/FetnaUserSlice"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

function FetnaUsers() {
  const dispatch = useDispatch()
  const { FetnaUserList, FetnaUserisSuccessfully } = useSelector(state => state.FetnaUserReducer)

  useEffect(() => {
    dispatch(fetchFetnaUser())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    //   {
    //     Header: "Action",
    //     Cell: cellProps => {
    //       return (
    //         <ul className="list-unstyled mb-0">
    //           <li>
    //             <Link
    //               to="#"
    //               className="btn btn-sm btn-soft-info me-2"
    //               onClick={() => {
    //                 const selectedUser = cellProps.row.original
    //                 localStorage.setItem("editFetnaUser", JSON.stringify(selectedUser._id))
    //               }}
    //             >
    //               <i className="mdi mdi-book-outline" id="edittooltip" />
    //             </Link>
    //           </li>
    //         </ul>
    //       )
    //     },
    //   },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Fetna User Management" />
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <div className="d-flex ">
                  <h5 className="mb-1 card-title flex-grow-1 text-primary">
                    Fetna Users
                  </h5>
                  <div className="flex-shrink-0 mb-1">
                    <ExportExcel
                      excelData={FetnaUserList}
                      fileName={"FETNA userList"}
                    />
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(fetchFetnaUser())
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Row>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={FetnaUserList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={15}
                      isJobListGlobalFilter={false}
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FetnaUsers
