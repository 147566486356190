import React, { useRef, useState, useEffect } from "react"
import { get, put } from "../../../../helpers/api_helper"
import { Link, useNavigate } from "react-router-dom"
import classnames from "classnames"
import moment from "moment"
import { Country } from "../../../../components/country-state-city/countries"
import { State } from "../../../../components/country-state-city/states"
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
    Container,
} from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//Import Date Picker

// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

// Redux
import { useSelector, useDispatch } from "react-redux"

import {
    createDay,
    updateDay,
} from "../../../../toolkitStore/FetnaSlice/DaySlice"
import { id } from "date-fns/locale"

const FetnaDayForm = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { DayisSuccessfully, isUserEdit } = useSelector(
        state => state.DayReducer
    )

    const [selectedData, SetSelectData] = useState()

    const event_id_c = JSON.parse(localStorage.getItem("event_id"))
    // console.log("event_id", event_id_c)
    const venue_id_c = JSON.parse(localStorage.getItem("venue_id"))

    // console.log("venue_id_c -->", venue_id_c)

    const nfds = []
    for (let i = 1; i <= event_id_c.EventDay; i++) {
        nfds.push(i)
    }

    useEffect(() => {
        if (DayisSuccessfully) {
            navigate("/fetna-day-list")
        }
    }, [DayisSuccessfully])

    useEffect(() => {
        const scheduleInfo = JSON.parse(localStorage.getItem("venue_id"))
        if (scheduleInfo) {
            SetSelectData(scheduleInfo.scheduleInfo)
        }
    }, [])

    //Cancel
    const Cancel = e => {
        e.preventDefault()
        localStorage.removeItem("venue_id")
        SetSelectData("")
        navigate("/fetna-day-list")
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            master_event_id: selectedData?.master_event_id || event_id_c.EventId,
            date: selectedData?.date ? moment.parseZone(selectedData?.date).format("YYYY-MM-DD") : "",
            day: selectedData?.day || ""
        },
        validationSchema: Yup.object({
            date: Yup.string().required(
                "Select Event Date"
            ),
            // day: Yup.string().required("Enter The Day"),
        }),
        onSubmit: (values, actions) => {
            {
                if (selectedData?._id) {
                    values["master_event_id"] = selectedData?.master_event_id
                    values["_id"] = selectedData?._id
                    //   values["remaining_tickets"] = values.max_tickets
                    dispatch(updateDay(values))
                } else {
                    values["master_event_id"] = event_id_c.EventId
                    //   values["remaining_tickets"] = values.max_tickets
                    dispatch(createDay(values))
                }
            }
            
            //navigate("/fetna-day-list")
            props.onClick()
            actions.resetForm()
        },
    })

    const filterstates = State.filter(
        sta => sta.country_name == validation.values.country
    )

    // console.log(filterstates)

    return (

                    <Row>
                        <Form
                            onSubmit={event => {
                                event.preventDefault()
                                validation.handleSubmit()
                            }}
                        >
                            <Row>
                                

                                        <Row >
                                            <Col>
                                                <div className="">
                                                    <label htmlFor="day">Day as (Optional)</label>
                                                    <input
                                                        type="text"
                                                        id="day"
                                                        name="day"
                                                        className="form-control"
                                                        placeholder="Enter your day as"
                                                        onChange={e =>
                                                            validation.setFieldValue("day", e.target.value)
                                                        }
                                                        value={validation.values.day}
                                                        min={1} // Set minimum value
                                                        max={31} // Set maximum value
                                                    />
                                                     <label className="my-2 text-secondary text-opacity-50" style={{fontSize:13,}}>Example : Day 1, Day 2 or 1, 2, 3</label>
                                                </div>
                                            </Col>

                                            <Col>
                                                <div className="">
                                                    <Label htmlFor="date">Event Date</Label>
                                                    <Input
                                                        name="date"
                                                        className="form-control"
                                                        type="date" // Change type to "date" here
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.date || ""}
                                                        invalid={
                                                            validation.touched.date &&
                                                                validation.errors.date
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.date &&
                                                        validation.errors.date ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.date}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col className="d-flex mt-3 justify-content-end">
                                                <div className="me-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success "
                                                    >
                                                        {selectedData?._id ? "Update" : "Submit"}
                                                    </button>
                                                </div>
                                                <div className="">
                                                    <button
                                                        onClick={() => props.onClick()}
                                                        className="btn btn-danger "
                                                    >
                                                        {"Cancel"}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    
                            </Row>
                        </Form>
                    </Row>
    )
}

export default FetnaDayForm
