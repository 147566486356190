import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Row, Modal, FormFeedback, UncontrolledTooltip
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

//import components
import TableContainer from "components/Common/TableContainer"

import{
    createEventOrganizers,
    updateEventOrganizers,
    deleteEventOrganizers
} from "../../../toolkitStore/FetnaSlice/fetnaSlice"


const EventOrganizer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {eventOrganizerList} = useSelector(state => state.FetnaReducer)

    const [modal_backdrop1, setmodal_backdrop1] = useState(false)
    const [dataMode, setDataMode] = useState(null)
    const [selectedHost, setSelectedHost] = useState(null)
    const [hostInfo, setHostInfo] = useState(null)

    const event_id_c = JSON.parse(localStorage.getItem("event_id"))
    // const agendaInfo = JSON.parse(localStorage.getItem('agenda_id'))

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_backdrop1() {
        setmodal_backdrop1(!modal_backdrop1);
        removeBodyCss();
    }

    useEffect(() => {
        console.log("agendaHostList", eventOrganizerList)
        if(eventOrganizerList){
            setHostInfo(eventOrganizerList)
        }
        else
        {
            setHostInfo(event_id_c.eventInfo.organiser)
        }

        return() => {}

    }, [eventOrganizerList])


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {

            name_org: selectedHost?.name_org || "",
            contact_number: selectedHost?.contact_number || "",
            email_id: selectedHost?.email_id || "",
            role: selectedHost?.role || ""
        },
        validationSchema: Yup.object({
            name_org: Yup.string().required("Name is required"),
            email_id: Yup.string().email("Invalid email").required("Email is required"),
            role: Yup.string().required("Role is required"),
        }),
        onSubmit: (values, actions) => {
            const agendaInfo = JSON.parse(localStorage.getItem('event_id'))
            
            if (dataMode === "New") {
                dispatch(createEventOrganizers(agendaInfo, values));
            }
            else
            {
                values["_id"] = selectedHost._id
                dispatch(updateEventOrganizers(agendaInfo, values));
            }
            actions.resetForm()
            setDataMode(null)
            setmodal_backdrop1(false);
        },
    });

    const deleteHostInfo = (data) => {
        const agendaInfo = JSON.parse(localStorage.getItem('event_id'))
        console.log("selectedHost", data)
        dispatch(deleteEventOrganizers(agendaInfo, data));
    }

    const columns = useMemo(
        () => [
            {
                Header: "Organizer Name",
                accessor: "name_org",
                disableFilters: true,
            },
            {
                Header: "Contact",
                accessor: "contact_number",
                disableFilters: true,
            },
            {
                Header: "Email",
                accessor: "email_id",
                disableFilters: true,
            },
            {
                Header: "Role",
                accessor: "role",
                disableFilters: true,
            },
            {
                Header: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: cellProps => {
                    const SData = cellProps.row.original
                    return (
                        <ul className="list-unstyled hstack  gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={e => {
                                        e.preventDefault();
                                        setDataMode("Edit")
                                        setSelectedHost(SData)
                                        tog_backdrop1()

                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={e => {
                                        e.preventDefault();
                                        setDataMode(null)
                                        deleteHostInfo(SData)
                                       
                                       
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            </ul>
                    )
                }
            }
        ],
        []
    )


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Manage Agenda Organizers" />
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center justify-content-between border-bottom border-secondary pb-3">
                                    <div>
                                        <div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Date: {moment(event_id_c.eventInfo.day).format("ll")}</label></div>
                                        <div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Event: {event_id_c.eventInfo.event_name}</label></div>
                                    </div>
                                    <div className="mb-1 flex-shrink-0">
                                        <Link
                                            to="#"
                                            className="btn btn-primary me-1"
                                            onClick={e => {
                                                e.preventDefault()
                                                setDataMode("New")
                                                setSelectedHost(null)
                                                tog_backdrop1()
                                            }}
                                        >
                                            Add Agenda Organizers
                                        </Link>
                                        <Link to="/fetna-event-list" className="btn btn-secondary me-1"
                                        >
                                            Back
                                        </Link>
                                        <Link
                                            to="#!"
                                            onClick={() => {
                                                // Refresh button logic
                                                
                                            }}
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>

                                <Row className="mt-3">
                                    {hostInfo && <div className="container-fluid">
                                        <TableContainer
                                            columns={columns}
                                            data={hostInfo}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            customPageSize={20}
                                            isJobListGlobalFilter={false}
                                            isColumnFilterEnabled={false}
                                        // className="custom-header-css"
                                        />
                                    </div>}
                                </Row>

                            </CardBody>

                            <Modal
                                isOpen={modal_backdrop1}
                                toggle={() => {
                                    tog_backdrop1();
                                }}
                                backdrop={'static'}
                                id="staticBackdrop"
                            >
                                <Form
                                    onSubmit={event => {
                                        console.log("test")
                                        event.preventDefault()
                                        validation.handleSubmit()
                                    }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">Create / Edit Agenda Organizers</h5>
                                        <button type="button" className="btn-close"
                                            onClick={() => {
                                                setmodal_backdrop1(false);
                                            }} aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                            <Row>
                                                <Col lg={12} className="mb-3">
                                                    <label htmlFor={`name_org`}>Organizer Name</label>
                                                    <input
                                                        type="text"
                                                        id={`name_org`}
                                                        name={`name_org`}
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        required
                                                        value={validation.values.name_org || ""}
                                                    />
                                                    {validation.touched.name_org && validation.errors.name_org ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name_org}
                                                        </FormFeedback>
                                                    ) : null}
                                                </Col>
                                                <Col lg={12} className="mb-3">
                                                    <label htmlFor={`contact_number`}>Contact Number (Optional)</label>
                                                    <input
                                                        type="text"
                                                        id={`contact_number`}
                                                        name={`contact_number`}
                                                        className="form-control"
                                                        placeholder="Enter Contact Number"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contact_number || ""}
                                                    />
                                                    {validation.touched.contact_number && validation.errors.contact_number ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.contact_number}
                                                        </FormFeedback>
                                                    ) : null}
                                                </Col>
                                                <Col lg={12} className="mb-3">
                                                    <label htmlFor={`email_id`}>Email</label>
                                                    <input
                                                        type="email"
                                                        id={`email_id`}
                                                        name={`email_id`}
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        required
                                                        value={validation.values.email_id || ""}
                                                    />
                                                    {validation.touched.email_id && validation.errors.email_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.email_id}
                                                        </FormFeedback>
                                                    ) : null}
                                                </Col>
                                                <Col lg={12} className="mb-3">
                                                    <label htmlFor={`role`}>Role</label>
                                                    <input
                                                        type="text"
                                                        id={`role`}
                                                        name={`role`}
                                                        className="form-control"
                                                        placeholder="Enter Role"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        required
                                                        value={validation.values.role || ""}
                                                    />
                                                    {validation.touched.role && validation.errors.role ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.role}
                                                        </FormFeedback>
                                                    ) : null}
                                                </Col>

                                                {/* <Col lg={2} className="align-self-center">
                                                        {validation.values.organiser.length > 1 && (
                                                            <div className="d-grid">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    onClick={() => onDeleteFormRow(index)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        )}
                                                    </Col> */}
                                            </Row>
                                        </div>

                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-success" >{dataMode === "New" ? "Submit" : "Update"}</button>
                                        <button type="button" className="btn btn-danger" onClick={() => {
                                            setmodal_backdrop1(false);
                                        }}>Cancel</button>
                                    </div>
                                </Form>

                            </Modal>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default EventOrganizer