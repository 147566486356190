import React, { useRef, useState, useEffect } from "react"
import { get, put } from "../../../../helpers/api_helper"
import { Link, useNavigate } from "react-router-dom"
import { APIServerIP } from "../../../../helpers/Sats_api_helper"
import classnames from "classnames"
import moment from "moment"
import { Country } from "../../../../components/country-state-city/countries"
import { State } from "../../../../components/country-state-city/states"
import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Form,
	Input,
	Label,
	Row,
	FormFeedback,
	Container,
	Button, Modal
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//Import Date Picker
// import "react-datepicker/dist/react-datepicker.css"

//Validation
import * as Yup from "yup"
import { useFormik, Field, FieldArray } from "formik"

// Redux
import { useSelector, useDispatch } from "react-redux"

import {
	createAgenda,
	updateAgenda,
	clearErrorMessage
} from "../../../../toolkitStore/FetnaSlice/AgendaSlice"
import { id } from "date-fns/locale"

const FetnaAgendaForm = props => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	// const { AgendaisSuccessfully, isUserEdit } = useSelector(
	// 	state => state.AgendaReducer
	// )

	const { AgendaisSuccessfully, isUserEdit, errorMessage } = useSelector(
		state => state.AgendaReducer
	)

	const handleCkeditorIn = (event, editor) => {
		const data = editor.getData()
		validation.setFieldValue("description", data)
	}

	const [selectedData, SetSelectData] = useState()
	const [image, SetImage] = useState()
	const [mealImage, setMealImage] = useState()

	const [modal_backdrop1, setmodal_backdrop1] = useState(false)

	function removeBodyCss() {
		document.body.classList.add("no_padding")
	}

	function tog_backdrop1() {
		setmodal_backdrop1(!modal_backdrop1);
		removeBodyCss();
	}

	const event_id_c = JSON.parse(localStorage.getItem("event_id"))
	// const location_day = JSON.parse(localStorage.getItem('location_id')).LocationDay;



	useEffect(() => {
		if (AgendaisSuccessfully) {
			navigate("/fetna-agenda-list")
		}
	}, [AgendaisSuccessfully])

	useEffect(() => {
		const agendaInfo = JSON.parse(localStorage.getItem("agenda_id"))
		console.log("agendaInfo", agendaInfo)
		if (agendaInfo) {
			SetSelectData(agendaInfo.agendaInfo)
		}
	}, [])

	//Cancel
	const Cancel = e => {
		e.preventDefault()
		dispatch(clearErrorMessage())
		localStorage.removeItem("editUser")
		SetSelectData("")
		navigate("/fetna-agenda-list")
	}

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			// master_event_id: selectedData?.master_event_id || event_id_c.EventId,
			name: selectedData?.name || "",
			agenda_cover_image: selectedData?.agenda_cover_image || "",
			meal_image: selectedData?.meal_image || "",
			description: selectedData?.description || "",
			start_time: selectedData?.start_time
				? moment.utc(selectedData?.start_time).format("HH:mm")
				: "",
			end_time: selectedData?.end_time
				? moment.utc(selectedData?.end_time).format("HH:mm")
				: "",
			agenda_type: selectedData?.agenda_type || "",
			tags: selectedData?.tags || "",
			group_category: selectedData?.group_category || "",
			room_no: selectedData?.room_no || "",
			room_name: selectedData?.room_name || "",

		},
		validationSchema: Yup.object({
			name: Yup.string().required("Enter Agenda Name"),
			start_time: Yup.string().required(
				" Enter Your Event Start Date and Time"
			),
			end_time: Yup.string().required(
				" Enter Your Event End Date and Time"
			),
			agenda_type: Yup.string().required("Select agenda type"),
			group_category: Yup.string().required("Select Category"),
		}),
		onSubmit: (values, actions) => {
			// console.log("hostImage", [image, ...hostImage]);
			if (selectedData?._id) {
				values["master_event_id"] = selectedData?.master_event_id
				values["master_date_id"] = selectedData?.master_date_id
				values["master_hall_id"] = selectedData?.master_hall_id
				values["master_location_id"] = selectedData?.master_location_id
				values["_id"] = selectedData?._id
				dispatch(updateAgenda(image, mealImage, values))
			} else {
				const hallId = JSON.parse(localStorage.getItem('hall_id')).hallId;
				const location_id = JSON.parse(localStorage.getItem('location_id')).LocationId;

				values["master_event_id"] = event_id_c.EventId
				values["master_date_id"] = event_id_c.DateId
				values["master_hall_id"] = hallId;
				values["master_location_id"] = location_id;
				dispatch(createAgenda(image, mealImage, values))
			}
			SetImage("")
			setMealImage("")
			// navigate("/fetna-agenda-list")
			actions.resetForm()
			// console.log("values", values);
		},
	})

	const filterstates = State.filter(
		sta => sta.country_name == validation.values.country
	)

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="" breadcrumbItem="Agenda Information" />
					{/* <div>
						{location_day && (
							<div>
								<h2>Date: <span style={{ color: "#556EE6" }}>{moment(location_day.day).format("MMM Do YY")}</span></h2>
							</div>
						)}
					</div> */}
					<Row>
						<Form
							onSubmit={event => {
								event.preventDefault()
								validation.handleSubmit()
							}}
						>
							<Row className="col-12 ">
								<Card>
									<CardBody>
										<div className="d-flex align-items-center border-bottom border-secondary pb-3">
											<div>
												<div style={{ margin: 0, pading: 0 }}><label className="text-primary m-0" style={{ fontSize: 16, lineHeight: 1 }}>Location: {event_id_c.eventInfo.name}</label></div>
												<div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Date: {moment(event_id_c.eventInfo.day).format("llll")}</label></div>
												<div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Event: {event_id_c.eventInfo.event_name}</label></div>
											</div>
											<h5 className="mb-1 card-title flex-grow-1 text-primary ">
												{/* {venue_id_c
                                                    ? event_id_c.EventName + " / " + venue_id_c.AgendaName
                                                    : event_id_c.EventName} */}
											</h5>
											<div className="mb-1 flex-shrink-0">
												<Link
													to="/fetna-agenda-list"
													className="btn btn-secondary me-1"
												>
													Back
												</Link>
												<Link
													to="#!"
													onClick={() => {
														/*  dispatch(fetchServices(userInformation.m_company_id)) */
													}}
													className="btn btn-light me-1"
												>
													<i className="mdi mdi-refresh"></i>
												</Link>
											</div>
										</div>
										<Row className="mb-3 mt-3">
											<Col className="">
												<Label htmlFor="name">Agenda Name</Label>
												<Input
													name="name"
													className={`form-control`}
													placeholder="Enter Agenda Name"
													type="text"
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.name || ""}
													invalid={
														validation.touched.name && validation.errors.name
															? true
															: false
													}
												/>
												{validation.touched.name && validation.errors.name ? (
													<FormFeedback
														type="invalid"
														style={{ fontSize: "12px" }}
													>
														{validation.errors.name}
													</FormFeedback>
												) : null}
											</Col>

											<Col>
												<div className="">
													<Label htmlFor="start_time">Start Time</Label>
													<Input
														name="start_time"
														className="form-control"
														type="time"
														onChange={(e) => {
															console.log("time", e.target.value)
															validation.handleChange(e)
														}}
														onBlur={validation.handleBlur}
														value={validation.values.start_time || ""}
														invalid={validation.touched.start_time && validation.errors.start_time ? true : false}
													/>
													{validation.touched.start_time && validation.errors.start_time ? (
														<FormFeedback type="invalid">{validation.errors.start_time}</FormFeedback>
													) : null}
												</div>
											</Col>
											<Col>
												<div className="">
													<Label htmlFor="end_time">End Time</Label>
													<Input
														name="end_time"
														className="form-control"
														type="time"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.end_time || ""}
														invalid={validation.touched.end_time && validation.errors.end_time ? true : false}
													/>
													{validation.touched.end_time && validation.errors.end_time ? (
														<FormFeedback type="invalid">{validation.errors.end_time}</FormFeedback>
													) : null}
												</div>
											</Col>
										</Row>
										<div>
											{errorMessage && (
												<div style={{ color: "red", display: "flex", justifyContent: "flex-end" }}>
													{errorMessage}
												</div>
											)}
										</div>
										<Row>
											<Col className="col-3">
												<div className="mb-3 col-12">
													<Label htmlFor="agenda_type" className="text-secondary text-opacity-75">
														Agenda Type
													</Label>
													<Input
														name="agenda_type"
														className={`form-select ${validation.touched.agenda_type && validation.errors.agenda_type ? 'is-invalid' : ''}`}
														type="select"
														value={validation.values.agenda_type || ""}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
													>
														<option value="" disabled>Select Type</option>
														<option value="Session">Session</option>
														<option value="Meal">Meal</option>
														<option value="Break">Break</option>
													</Input>
													{validation.touched.agenda_type && validation.errors.agenda_type ? (
														<FormFeedback type="invalid">{validation.errors.agenda_type}</FormFeedback>
													) : null}
												</div>
											</Col>
											<Col className="col-3">
												<div className="mb-3 col-12">
													<Label htmlFor="tags" className="text-secondary text-opacity-75">
														Tags
													</Label>
													<Input
														name="tags"
														className={`form-control`}
														type="select"
														value={validation.values.tags || ""}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
													>
														<option value="" disabled>Select Type</option>
														<option value="Workshop">Workshop</option>
														<option value="Keynote">Keynote</option>
														<option value="Networking">Networking</option>
													</Input>
												</div>
											</Col>
											<Col className="col-3">
												<div className="mb-3 col-12">
													<Label htmlFor="group_category" className="text-secondary text-opacity-75">
														Category
													</Label>
													<Input
														name="group_category"
														className={`form-select ${validation.touched.group_category && validation.errors.group_category ? 'is-invalid' : ''}`}
														type="select"
														value={validation.values.group_category || ""}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
													>
														<option value="" disabled>Select Category</option>
														<option value="General">General</option>
														<option value="Women centric">Women centric</option>
														<option value="Youth Wing">Youth Wing</option>
														<option value="Others">Others</option>
													</Input>
												</div>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col lg={6} className="">
												<div className="">
													<Label htmlFor="agenda_cover_image">Agenda Photos</Label>
													<Input
														name="agenda_cover_image"
														className="form-control"
														placeholder="Enter The Designation"
														type="file"
														onChange={e => {
															validation.setFieldValue(
																"agenda_cover_image",
																e.currentTarget.files[0].name
															)
															SetImage(e.currentTarget.files[0])
														}}
														invalid={
															validation.touched.agenda_cover_image &&
																validation.errors.agenda_cover_image
																? true
																: false
														}
													// disabled={dis1}
													/>
													{validation.touched.agenda_cover_image &&
														validation.errors.agenda_cover_image ? (
														<div
															type="invalid"
															className="invalid-feedback"
															style={{ fontSize: "12px" }}
														>
															{validation.errors.agenda_cover_image}
														</div>
													) : selectedData?.agenda_cover_image ? (
														<a
															href={`${APIServerIP}uploads/${selectedData?.agenda_cover_image}`}
														>
															{selectedData?.agenda_cover_image}
														</a>
													) : (
														validation.values.agenda_cover_image
													)}
												</div>
											</Col>
											<Col className="col-3">
												<div className="">
													<Label htmlFor="room_no">Room No</Label>
													<Input
														name="room_no"
														min={1}
														className={`form-control`}
														placeholder="Enter Room No"
														type="text"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.room_no || ""}
														invalid={
															validation.touched.room_no &&
																validation.errors.room_no
																? true
																: false
														}
													/>
													{validation.touched.room_no &&
														validation.errors.room_no ? (
														<FormFeedback
															type="invalid"
															style={{ fontSize: "12px" }}
														>
															{validation.errors.room_no}
														</FormFeedback>
													) : null}
												</div>
											</Col>
											<Col className="col-3">
												<div className="">
													<Label htmlFor="room_name">Room Name</Label>
													<Input
														name="room_name"
														min={1}
														className={`form-control`}
														placeholder="Enter Room Name"
														type="text"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.room_name || ""}
														invalid={
															validation.touched.room_name &&
																validation.errors.room_name
																? true
																: false
														}
													/>
													{validation.touched.room_name &&
														validation.errors.room_name ? (
														<FormFeedback
															type="invalid"
															style={{ fontSize: "12px" }}
														>
															{validation.errors.room_name}
														</FormFeedback>
													) : null}
												</div>
											</Col>
										</Row>
										{validation.values.agenda_type === "Meal" && (
											<Row className="mb-3">
												<Col lg={6} className="">
													<div className="">
														<Label htmlFor="meal_image">Meal Photos</Label>
														<Input
															name="meal_image"
															className="form-control"
															type="file"
															onChange={e => {
																validation.setFieldValue(
																	"meal_image",
																	e.currentTarget.files[0].name
																)
																setMealImage(e.currentTarget.files[0])
															}}
															invalid={
																validation.touched.meal_image &&
																	validation.errors.meal_image
																	? true
																	: false
															}
														/>
														{validation.touched.meal_image &&
															validation.errors.meal_image ? (
															<div
																type="invalid"
																className="invalid-feedback"
																style={{ fontSize: "12px" }}
															>
																{validation.errors.meal_image}
															</div>
														) : selectedData?.meal_image ? (
															<a
																href={`${APIServerIP}uploads/${selectedData?.meal_image}`}
															>
																{selectedData?.meal_image}
															</a>
														) : (
															validation.values.meal_image
														)}
													</div>
												</Col>
											</Row>
										)}
										<Row>
											<Col className="col-12">
												<div className="">
													<Label htmlFor="description">Description</Label>
													<CKEditor
														config={{
															placeholder: "Write about event...",
														}}
														editor={ClassicEditor}
														data={validation.values.description}
														onChange={handleCkeditorIn}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col className="d-flex mt-3 justify-content-end">
												<div className=" text-center mt-3 ">
													<button
														type="submit"
														className="btn btn-success w-md px-5"
													>
														{selectedData?._id ? "Update" : "Submit"}
													</button>
												</div>
												<div className=" text-center mt-3 mx-3 ">
													<button
														onClick={Cancel}
														className="btn btn-danger w-md px-5"
													>
														{"Cancel"}
													</button>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Row>
						</Form>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default FetnaAgendaForm
