import React, { useRef, useState, useEffect } from "react"
/* import axios from "axios" */
import { Link, useNavigate } from "react-router-dom"
import { APIServerIP } from "../../../helpers/Sats_api_helper"
import moment from "moment"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

//Import Date Picker
import { format } from "date-fns"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Validation
import * as Yup from "yup"
import { useFormik, Field } from "formik"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  createFetnaEvent,
  updateFetnaEvent,
  resetEvent,
} from "../../../toolkitStore/FetnaSlice/fetnaSlice"
import { Feedback } from "@mui/icons-material"

function FetnaEventForm(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { newEventList, isSuccessfullyAdded, isUserEdit } = useSelector(
    state => state.EventReducer
  )

  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    validation.setFieldValue("description", data)
  }

  document.title = "Form File Upload | Skote - React Admin & Dashboard Template"

  const [image, SetImage] = useState()
  const [selectedData, SetSelectData] = useState()
  const [dis4, SetDis4] = useState(false)
  const [dis1, SetDis1] = useState(false)

  const event_id_c = JSON.parse(localStorage.getItem("event_id"))

  useEffect(() => {
    const eventInfo = JSON.parse(localStorage.getItem("event_id"))
    console.log("eventInfo", eventInfo)
    if (eventInfo) { 
      SetSelectData(eventInfo.eventInfo)
    }
  }, [])

  //Cancel
  const Cancel = e => {
    e.preventDefault()
    localStorage.removeItem("event_id")
    SetSelectData("")
    navigate("/fetna-event-list")
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: selectedData?.name || "",
      description: selectedData?.description || "",
      image_name: selectedData?.image_name || "",
      
    },
    validationSchema: Yup.object({
      name: Yup.string().required(" Enter Your Event Name"),
      // description: Yup.string().required("Enter event description"),
      image_name: Yup.string().required("Event image is required")
    }),
    onSubmit: (values, actions) => {
      if (selectedData?._id) {
        values["_id"] = selectedData?._id
        console.log("mm", values)
        dispatch(updateFetnaEvent(image, values))
      } else {
        dispatch(createFetnaEvent(image, values))
      }

      actions.resetForm()
      SetImage("")
      navigate("/fetna-event-list")
      console.log(values)
    },
  })

  const [formRows, setFormRows] = useState([{ id: 1 }]);

	// const onAddFormRow = () => {
	// 	const modifiedRows = [...formRows];
	// 	modifiedRows.push({ id: modifiedRows.length + 1 });
	// 	setFormRows(modifiedRows);
	// 	const fieldValue = validation.values.organiser

	// 	validation.setFieldValue('organiser', [...fieldValue, {
	// 		name: "",
	// 		contact_number: "",
	// 		email_id: "",
  //     role: ""
	// 	}])
	// };

	// const onDeleteFormRow = id => {
	// 	if (id !== 1) {
	// 		var modifiedRows = [...formRows];
	// 		modifiedRows = modifiedRows.filter(x => x["id"] !== id);
	// 		setFormRows(modifiedRows);
	// 	}
	// };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Fetna Event Information" />
          <Row>
            <Form
              onSubmit={event => {
                console.log("submit");
                event.preventDefault()
                validation.handleSubmit()
              }}
            >
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center border-bottom border-secondary pb-3">
                    <h5 className="mb-1 card-title flex-grow-1 text-primary ">
                      {event_id_c ? event_id_c.EventName : "New Event"}
                    </h5>
                    <div className="mb-1 flex-shrink-0">
                      <Link to="/fetna-event-list" className="btn btn-secondary me-1">
                        Back
                      </Link>
                      <Link
                        to="#!"
                        onClick={() => {
                        }}
                        className="btn btn-light me-1"
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                  </div>

                  <Row className="mt-3 mb-3">
                    <Col lg={12} className="mb-3">
                      <div className="">
                        <Label htmlFor="name">Event Name</Label>
                        <Input
                          name="name"
                          className={`form-control`}
                          placeholder="Enter Event Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                          disabled={dis4}
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={12} className="mb-3">
                      <div
                        className="" //position-relative
                      >
                        <Label htmlFor="how_to">Description</Label>
                        <CKEditor
                          config={{
                            placeholder: "Write about event...",
                          }}
                          editor={ClassicEditor}
                          data={validation.values.description}
                          onChange={handleCkeditorIn}
                          disabled={dis4}
                        />
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="">
                        <Label htmlFor="image_name">Event Photos</Label>
                        <Input
                          name="image_name"
                          className="form-control"
                          placeholder="Enter The Designation"
                          type="file"
                          onChange={e => {
                            validation.setFieldValue(
                              "image_name",
                              e.currentTarget.files[0].name
                            )
                            SetImage(e.currentTarget.files[0])
                          }}
                          invalid={
                            validation.touched.image_name &&
                            validation.errors.image_name
                              ? true
                              : false
                          }
                          disabled={dis1}
                        />
                        {validation.touched.image_name &&
                        validation.errors.image_name ? (
                          <div
                            type="invalid"
                            className="invalid-feedback"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.image_name}
                          </div>
                        ) : selectedData?.image_name ? (
                          <a
                            href={`${APIServerIP}uploads/${selectedData?.image_name}`}
                          >
                            {selectedData?.image_name}
                          </a>
                        ) : (
                          validation.values.image_name
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
											<Col xs={12}>
												<Card>
													<CardBody>
														<h6 className="mb-4 card-title">Organiser Details</h6>
														<div>
															{(validation.values.organiser).map((formRow, key) => (
																<Row key={key}>
																	<Col lg={2} className="mb-3">
																		<label htmlFor="name">Organiser Name</label>
																		<input
																			type="text"
																			id="name"
																			name={validation.values.organiser?.[key]['name']}
																			className="form-control"
																			placeholder="Enter Your Name"
																			onChange={(e) => {
																				validation.setFieldValue(`organiser[${key}].name`, e.currentTarget.value)
																			}}
																			onBlur={(e) => {
																				validation.setFieldValue(`organiser[${key}].name`, e.currentTarget.value)
																			}}
																			value={validation.values.organiser?.[key]['name'] || ""}
																		/>
																	</Col>
																	<Col lg={2} className="mb-3">
																		<label htmlFor="contact_number">Contact Number</label>
																		<input
																			type="text"
																			id="contact_number"
																			name="contact_number"
																			className="form-control"
																			placeholder="Enter Your Contact Number"
																			onChange={(e) => {
																				validation.setFieldValue(`organiser[${key}].contact_number`, e.currentTarget.value)
																			}}
																			onBlur={(e) => {
																				validation.setFieldValue(`organiser[${key}].contact_number`, e.currentTarget.value)
																			}}
																			value={validation.values.organiser?.[key]['contact_number'] || ""}
																		/>
																	</Col>
                                  <Col lg={2} className="mb-3">
																		<label htmlFor="email_id">Email</label>
																		<input
																			type="email"
																			id="email_id"
																			name="email_id"
																			className="form-control"
																			placeholder="Enter Your Email"
																			onChange={(e) => {
																				validation.setFieldValue(`organiser[${key}].email_id`, e.currentTarget.value)
																			}}
																			onBlur={(e) => {
																				validation.setFieldValue(`organiser[${key}].email_id`, e.currentTarget.value)
																			}}
																			value={validation.values.organiser?.[key]['email_id'] || ""}
																		/>
																	</Col>
                                  <Col lg={2} className="mb-3">
																		<label htmlFor="email_id">Role</label>
																		<input
																			type="text"
																			id="role"
																			name="role"
																			className="form-control"
																			placeholder="Enter Your Role"
																			onChange={(e) => {
																				validation.setFieldValue(`organiser[${key}].role`, e.currentTarget.value)
																			}}
																			onBlur={(e) => {
																				validation.setFieldValue(`organiser[${key}].role`, e.currentTarget.value)
																			}}
																			value={validation.values.organiser?.[key]['role'] || ""}
																		/>
																	</Col>
																	<Col lg={2} className="align-self-center">
																		<div className="d-grid">
																			<input
																				type="button"
																				className="btn btn-primary"
																				value="Delete"
																				onClick={() => onDeleteFormRow(formRow.id)}
																			/>
																		</div>
																	</Col>
																</Row>
															))}
														</div>

														<input
															type="button"
															className="btn btn-success mt-3 mt-lg-0"
															value="Add"
															onClick={() => onAddFormRow()}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row> */}

                  <Col className="d-flex mt-3 justify-content-end">
                    <div className=" text-center mt-3 ">
                      <button
                        type="submit"
                        className="btn btn-success w-md px-5"
                      >
                        {selectedData?._id ? "Update" : "Submit"}
                      </button>
                    </div>
                    <div className=" text-center mt-3 mx-3 ">
                      <button
                        onClick={Cancel}
                        className="btn btn-danger w-md px-5"
                      >
                        {"Cancel"}
                      </button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FetnaEventForm
