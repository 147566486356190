import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { del, get, post, put } from "../../helpers/api_helper"

import { APIServerIP } from "../../helpers/Sats_api_helper"

const initialEveState = {
  // companyInfo: null,
  newFetnaEventList: [],
  EventListWithvenue: [],
  isSuccessfullyAdded: false,
  organiserList:null,
  isUserEdit: false,
  agendaHostList:null,
  agendaOrganizersList:null,
  eventOrganizerList:null
}

const eventFormSlice = createSlice({
  name: "EventFromValue",
  initialState: initialEveState,
  reducers: {
    setFetnaEventList: (state, action) => {
      state.newFetnaEventList = action.payload
      // state.newEventList = [...state.newEventList, action.payload]
    },

    setFetnaEventListVenue: (state, action) => {
      state.EventListWithvenue = action.payload
    },
    setUserEdit: (state, action) => {
      state.isUserEdit = true
    },
    setEventSuccessfullyAdded: (state, action) => {
      state.isSuccessfullyAdded = true
    },
    resetFetnaEvent: (state, action) => {
      state.isSuccessfullyAdded = false
      state.isUserEdit = false
    },
    setOrganiserList:(state, action) => {
      state.organiserList=action.payload
    },
    setHostList:(state, action) => {
      state.agendaHostList=action.payload
    },
    setAgendaOrganizersList:(state, action) => {
      state.agendaOrganizersList=action.payload
    },
    setEventOrganizersList:(state, action) => {
      state.eventOrganizerList=action.payload
    }
  },
})

export const createFetnaEvent = (image, values) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("file", image)
    formData.append("values", JSON.stringify(values))

    dispatch(resetFetnaEvent())
    await fetch(`${APIServerIP}fetna-event/create-fetna-event`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(setEventSuccessfullyAdded())
        }
      })
      .catch(err => {
        console.log("try error", err)
      })
  }
}

export const updateFetnaEvent = (image, values) => {

  return async dispatch => {

    const formData1 = new FormData()
    formData1.append("file", image),
      formData1.append("values", JSON.stringify(values))

    dispatch(resetFetnaEvent())
    await fetch(`${APIServerIP}fetna-event/update-fetna-event`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData1,
    })
      .then(res => {
        if (res.status == 200) {
          dispatch(setEventSuccessfullyAdded())
          localStorage.removeItem("editUser")
        }
      })
      .catch(err => {
        console.log("Updatetry error", err)
      })
  }
}

export const deleteFetnaEvent = id => {
  return async dispatch => {
    try {
      dispatch(resetFetnaEvent())
      const responseData = await post(
        `${APIServerIP}fetna-event/delete-fetna-event`,
        id
      )

      if (responseData.response_code === 200) {
        dispatch(setEventSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}
export const activeInactiveEvent = data => {
  return async dispatch => {
    try {
      dispatch(resetFetnaEvent())
      const responseData = await post(
        `${APIServerIP}manage-event/active-inactive-event`,
        data
      )

     

      if (responseData.response_code === 200) {
        dispatch(setEventSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error", error)
    }
  }
}

export const editUser = data => {
  return async dispatch => {
    try {
      localStorage.setItem("editUser", JSON.stringify(data))
      dispatch(setUserEdit())
    } catch (err) {
      console.log("editUsererr", err)
    }
  }
}

export const fetchFetnaEvent = () => {
  return async dispatch => {
    try {
      let responseData = await get(`${APIServerIP}fetna-event/fetch-fetna-events`)
      if (responseData.response_code === 200) {
        dispatch(setFetnaEventList(responseData.data))
        dispatch(resetFetnaEvent())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const updateFetnaEventOrganiser = (eventId, data) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna/update-event-organiser`,
        {_id: eventId, ...data}
      )

      console.log("responseData", responseData.data)
      if (responseData.response_code === 200) {
        dispatch(setOrganiserList(responseData.data.organiser))
        dispatch(setEventSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const PublishEvent = data => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}manage-event/edit-publish-event`,
        data
      )
      if (responseData.response_code === 200) {
        dispatch(setEventSuccessfullyAdded())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}
export const fetchEventandVenue = () => {
  return async dispatch => {
    try {
      let responseData = await get(
        `${APIServerIP}manage-event/fetch-event-and-venues-publish`
      )
      // console.log("active get users", responseData)
      if (responseData.response_code === 200) {
        dispatch(setFetnaEventListVenue(responseData.data))
        dispatch(resetFetnaEvent())
      }
    } catch (error) {
      console.log("try error fetch", error)
    }
  }
}

export const createHost = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/create-agenda-host`,{
          agenda_id:agendaInfo.AgendaId,
          host_info:info
        }
      )
      // console.log("responseData", responseData.data)
      if (responseData.status === 200) {
        dispatch(setHostList(responseData.data.event_host_by))
      }

    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const updateHost = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/update-agenda-host`,{
          agenda_id:agendaInfo.AgendaId,
          host_info:info
        }
      )
      // console.log("update response", responseData.data)
      if (responseData.status === 200) {
        dispatch(setHostList(responseData.data.event_host_by))
      }


    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const deleteHost = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/delete-agenda-host`,{
          agenda_id:agendaInfo.AgendaId,
          host_info:info
        }
      )
      // console.log("delete response", responseData.data)
      if (responseData.status === 200) {
        dispatch(setHostList(responseData.data.event_host_by))
      }


    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const createAgendaOrganizers = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/create-agenda-organizer`,{
          agenda_id:agendaInfo.AgendaId,
          host_info:info
        }
      )
      // console.log("responseData", responseData.data)
      if (responseData.status === 200) {
        dispatch(setAgendaOrganizersList(responseData.data.event_organizers))
      }

    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const updateAgendaOrganizers = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/update-agenda-organizer`,{
          agenda_id:agendaInfo.AgendaId,
          host_info:info
        }
      )
      // console.log("update response", responseData.data)
      if (responseData.status === 200) {
        dispatch(setAgendaOrganizersList(responseData.data.event_organizers))
      }


    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const deleteAgendaOrganizers = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/delete-agenda-organizer`,{
          agenda_id:agendaInfo.AgendaId,
          host_info:info
        }
      )
      // console.log("delete response", responseData.data)
      if (responseData.status === 200) {
        dispatch(setAgendaOrganizersList(responseData.data.event_organizers))
      }


    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const createEventOrganizers = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/create-event-organizer`,{
          agenda_id:agendaInfo.EventId,
          host_info:info
        }
      )
       console.log("responseData", responseData.data)
      if (responseData.status === 200) {
        dispatch(setEventOrganizersList(responseData.data.organiser))
      }

    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const updateEventOrganizers = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/update-event-organizer`,{
          agenda_id:agendaInfo.EventId,
          host_info:info
        }
      )
      // console.log("update response", responseData.data)
      if (responseData.status === 200) {
        dispatch(setEventOrganizersList(responseData.data.organiser))
      }


    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const deleteEventOrganizers = (agendaInfo, info) => {
  return async dispatch => {
    try {
      let responseData = await post(
        `${APIServerIP}fetna-agendas/delete-event-organizer`,{
          agenda_id:agendaInfo.EventId,
          host_info:info
        }
      )
      // console.log("delete response", responseData.data)
      if (responseData.status === 200) {
        dispatch(setEventOrganizersList(responseData.data.organiser))
      }


    }
    catch (error) {
      console.log("catch error", error)
    }
  }
}

export const {
  setFetnaEventList,
  setEventSuccessfullyAdded,
  resetFetnaEvent,
  setFetnaEventListVenue,
  setUserEdit,
  setOrganiserList,
  setHostList,
  setAgendaOrganizersList,
  setEventOrganizersList
} = eventFormSlice.actions

export default eventFormSlice.reducer
