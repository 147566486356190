import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Row, Modal, FormFeedback, UncontrolledTooltip
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

//import components
import TableContainer from "components/Common/TableContainer"

import{
    createHost,
    updateHost,
    deleteHost
} from "../../../../toolkitStore/FetnaSlice/fetnaSlice"


const AgendaHost = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {agendaHostList} = useSelector(state => state.FetnaReducer)

    const [modal_backdrop1, setmodal_backdrop1] = useState(false)
    const [dataMode, setDataMode] = useState(null)
    const [selectedHost, setSelectedHost] = useState(null)
    const [hostInfo, setHostInfo] = useState(null)

    const event_id_c = JSON.parse(localStorage.getItem("event_id"))
    const agendaInfo = JSON.parse(localStorage.getItem('agenda_id'))
    const hallInfo = JSON.parse(localStorage.getItem("hall_id"))

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_backdrop1() {
        setmodal_backdrop1(!modal_backdrop1);
        removeBodyCss();
    }

    useEffect(() => {
        console.log("agendaHostList", agendaHostList)
        if(agendaHostList){
            setHostInfo(agendaHostList)
        }
        else
        {
            setHostInfo(agendaInfo.agendaInfo.event_host_by)
        }

        return() => {}

    }, [agendaHostList])


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {

            host_name: selectedHost?.host_name || "",
            description: selectedHost?.description || "",
        },
        validationSchema: Yup.object({
            host_name: Yup.string().required("Name is required"),
        }),
        onSubmit: (values, actions) => {
            const eventId = JSON.parse(localStorage.getItem('event_id')).EventId
            const agendaInfo = JSON.parse(localStorage.getItem('agenda_id'))
            
            if (dataMode === "New") {
                dispatch(createHost(agendaInfo, values));
            }
            else
            {
                values["_id"] = selectedHost._id
                dispatch(updateHost(agendaInfo, values));
            }
            actions.resetForm()
            setDataMode(null)
            setmodal_backdrop1(false);
        },
    });

    const deleteHostInfo = (data) => {
        const agendaInfo = JSON.parse(localStorage.getItem('agenda_id'))
        console.log("selectedHost", data)
        dispatch(deleteHost(agendaInfo, data));
    }

    const columns = useMemo(
        () => [
            {
                Header: "Host Name",
                accessor: "host_name",
                disableFilters: true,
            },
            {
                Header: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: cellProps => {
                    const SData = cellProps.row.original
                    return (
                        <ul className="list-unstyled hstack  gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={e => {
                                        e.preventDefault();
                                        setDataMode("Edit")
                                        setSelectedHost(SData)
                                        tog_backdrop1()

                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={e => {
                                        e.preventDefault();
                                        setDataMode(null)
                                        deleteHostInfo(SData)
                                       
                                       
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            </ul>
                    )
                }
            }
        ],
        []
    )


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Manage Agenda Host" />
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center justify-content-between border-bottom border-secondary pb-3">
                                    <div>
                                        <div style={{ margin: 0, pading: 0 }}><label className="text-primary m-0" style={{ fontSize: 16, lineHeight: 1 }}>Agenda: {agendaInfo.agendaInfo.name}</label></div>
                                        <div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Room: {hallInfo.hallInfo.name}, &nbsp;&nbsp; Location: {event_id_c.eventInfo.name}</label></div>
                                        <div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Date: {moment(event_id_c.eventInfo.day).format("ll")}</label></div>
                                        <div><label className="text-secondary m-0" style={{ fontSize: 13, lineHeight: 1 }}>Event: {event_id_c.eventInfo.event_name}</label></div>
                                    </div>
                                    <div className="mb-1 flex-shrink-0">
                                        <Link
                                            to="#"
                                            className="btn btn-primary me-1"
                                            onClick={e => {
                                                e.preventDefault()
                                                setDataMode("New")
                                                setSelectedHost(null)
                                                tog_backdrop1()
                                            }}
                                        >
                                            Add Agenda Host
                                        </Link>
                                        <Link to="/fetna-agenda-list" className="btn btn-secondary me-1"
                                        >
                                            Back
                                        </Link>
                                        <Link
                                            to="#!"
                                            onClick={() => {
                                                // Refresh button logic
                                                
                                            }}
                                            className="btn btn-light me-1"
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                        </Link>
                                    </div>
                                </div>

                                <Row className="mt-3">
                                    {hostInfo && <div className="container-fluid">
                                        <TableContainer
                                            columns={columns}
                                            data={hostInfo}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            customPageSize={20}
                                            isJobListGlobalFilter={false}
                                            isColumnFilterEnabled={false}
                                        // className="custom-header-css"
                                        />
                                    </div>}
                                </Row>

                            </CardBody>

                            <Modal
                                isOpen={modal_backdrop1}
                                toggle={() => {
                                    tog_backdrop1();
                                }}
                                backdrop={'static'}
                                id="staticBackdrop"
                            >
                                <Form
                                    onSubmit={event => {
                                        console.log("test")
                                        event.preventDefault()
                                        validation.handleSubmit()
                                    }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">Create / Edit Agenda Host</h5>
                                        <button type="button" className="btn-close"
                                            onClick={() => {
                                                setmodal_backdrop1(false);
                                            }} aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col lg={12} className="mb-3">
                                                <label htmlFor={`name_org`}>Host Name</label>
                                                <input
                                                    type="text"
                                                    id={`host_name`}
                                                    name={`host_name`}
                                                    className="form-control"
                                                    placeholder="Enter Name"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    required
                                                    value={validation.values.host_name || ""}
                                                />
                                                {validation.touched.host_name && validation.errors.host_name ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.host_name}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>
                                            <Col lg={12} className="mb-3">
                                                <label htmlFor={`description`}>Description</label>
                                                <textarea
                                                    rows="3"
                                                    id={`description`}
                                                    name={`description`}
                                                    className="form-control"
                                                    placeholder="Enter description"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.description || ""}
                                                />
                                                
                                            </Col>

                                           
                                        </Row>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-success" >{dataMode === "New" ? "Submit" : "Update"}</button>
                                        <button type="button" className="btn btn-danger" onClick={() => {
                                            setmodal_backdrop1(false);
                                        }}>Cancel</button>
                                    </div>
                                </Form>

                            </Modal>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default AgendaHost